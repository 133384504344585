import {useContext, useEffect, useState} from 'react';
import * as signalR from '@microsoft/signalr';
import {parseMessage, TMessage, TMessageUser} from "../types/TMessage";
import {MainContext} from "../context/MainContext";
import {UserType} from "../types/TUserData";

export interface MessageHookReturn {
  setHookProps: ({lastMessageUnix}: MessageHookProps) => void;
  addMessage: (message: TMessage) => void;
  messages: TMessage[];
}

export interface MessageHookProps {
  lastMessageUnix: number;
  users: TMessageUser[];
  reset: boolean
}

export const MessageHook = (): MessageHookReturn => {
  const mainContext = useContext(MainContext);
  const [connection, setConnection] = useState<signalR.HubConnection | undefined>(undefined);
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [hookProps, SetHookProps] = useState<undefined | MessageHookProps>(undefined)

  async function setHookProps({reset, lastMessageUnix, users}: MessageHookProps) {
    if (reset) {
      setMessages([])
      if (connection != undefined) {
        await connection.stop()
        setConnection(undefined);
      }
    }
    SetHookProps({lastMessageUnix, users: users, reset: reset})
  }

  useEffect(() => {
    if (hookProps && hookProps.reset === true) {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl("https://apiv2.pofft.com/notificationHub")
        .configureLogging(signalR.LogLevel.Information)
        .build();
      setConnection(newConnection);
    }
  }, [hookProps]);

  function addMessage(message: TMessage) {
    setMessages((prevMessages) => [...prevMessages, message]);
  }

  useEffect(() => {
    if (connection && connection.state === signalR.HubConnectionState.Disconnected) {
      console.log('Connecting to SignalR Hub');
      connection.start()
        .then(result => {
          handleSocket(connection);
        })
        .catch(e => {
          debugger
          console.log('Connection failed: ', e)
        });
    }
  }, [connection, messages]);

  function handleSocket(connection: signalR.HubConnection) {
    let socketKey = 'Message_' + ((mainContext!.userData!.userType == UserType.Company) ? '1_' : '0_') + mainContext!.userData!.id;
    console.log('Socket Key: ', socketKey)
    connection.on(socketKey, message => {
      console.log('Message Received: ', message);
      let jsonMessage = JSON.parse(message);
      const parsedMessage = parseMessage(jsonMessage, hookProps!.users);
      let newProps = {
        users: hookProps!.users,
        lastMessageUnix: parsedMessage.dateObj.getTime(),
        reset: false
      };
      setHookProps(newProps)
      addMessage(parsedMessage);
    });
  }

  return {setHookProps, addMessage, messages};
}
