import { Button } from "@mui/material";
import { THomeHero } from "../types/THero";

interface PHeroHeaderText {
  data: THomeHero;
}

function CHeroHeaderText({ data }: PHeroHeaderText) {
  return (
    <div className="flex-col justify-left lg:pr-[32px] text-center lg:text-start flex-1">
      <div className="text-14-18-400_16-24-400 text-grey tracking-[1.4px]">{data.title}</div>
      <div className="pt-[16px] text-32-40-600_54-64-600 text-green" dangerouslySetInnerHTML={{__html:data.header}}/>
      <div className="pt-[16px] text-14-18-400_16-24-400">{data.sub}</div>
      <div className="pt-[24px]">
        <Button className="purpleButton" onClick={()=>{data.buttonFunction()}}>
          <span>{data.buttonText}</span>
        </Button>
      </div>
    </div>
  );
}

export default CHeroHeaderText;
