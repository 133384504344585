import {FilterModal} from "../Filter/FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {CSocialMediaContainer} from "../CSocialMediaContainer";
import {SocialMedias} from "../../types/TCompanyData";
import {TError} from "../../types/TError";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  data: SocialMedias
  getTranslation?: (parent: string, child: string) => string
  isOpen: boolean
  close: () => void
  updated: () => void
  update: (data: any) => Promise<any>
}

export function CProfileSocialModal({getTranslation, isOpen, close, update, updated, data}: IProps) {
  const mainContext = useContext(MainContext)

  const [tempData, setTempData] = useState(data)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    setTempData(data)
  }, [data]);

  function save() {
    if (mainContext?.isExpired('profilesocial')) {
      return
    }
    setIsUpdating(true)
    update(tempData).then(r => {
      if (r instanceof TError || r.data.result == false) {
        mainContext?.showToast(NotificationType.Fail, 'updateSocialMedia')
        return
      }
      mainContext?.showToast(NotificationType.Success, 'updateSocialMedia')
      updated()
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateSocialMedia')
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  return <FilterModal
    title={getTranslation?.('profile', 'sosyal-medya-linklerim') ?? ''}
    header={''}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={isUpdating}
    saveDisabled={isUpdating}
    customHeaderClass={'!pb-[20px]'}
  >
    <div>
      <div className={'pt-0 mt-0'}>
        <div
          className={'modalSub pb-[52px]'}>{getTranslation?.('profile', 'sosyal-medya-duzenleme-aciklama') ?? ''}</div>
      </div>
      <div className={'px-[25px] flex-col gap-[16px]'}>
        <CSocialMediaContainer getTranslation={mainContext?.getTranslation} socialMedia={tempData.twitter}
                               isUpdating={isUpdating}
                               onUpdate={(s) => {
                                 setTempData({...tempData, twitter: s})
                               }}/>
        <CSocialMediaContainer getTranslation={mainContext?.getTranslation} socialMedia={tempData.linkedin}
                               isUpdating={isUpdating}
                               onUpdate={(s) => {
                                 setTempData({...tempData, linkedin: s})
                               }}/>
        <CSocialMediaContainer getTranslation={mainContext?.getTranslation} socialMedia={tempData.facebook}
                               isUpdating={isUpdating}
                               onUpdate={(s) => {
                                 setTempData({...tempData, facebook: s})
                               }}/>
        <CSocialMediaContainer getTranslation={mainContext?.getTranslation} socialMedia={tempData.dribble}
                               isUpdating={isUpdating}
                               onUpdate={(s) => {
                                 setTempData({...tempData, dribble: s})
                               }}/>
        <CSocialMediaContainer getTranslation={mainContext?.getTranslation} socialMedia={tempData.behance}
                               isUpdating={isUpdating}
                               onUpdate={(s) => {
                                 setTempData({...tempData, behance: s})
                               }}/>
        <CSocialMediaContainer getTranslation={mainContext?.getTranslation} socialMedia={tempData.instagram}
                               isUpdating={isUpdating}
                               onUpdate={(s) => {
                                 setTempData({...tempData, instagram: s})
                               }}/>
      </div>
    </div>
  </FilterModal>
}
