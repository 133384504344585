import MenuArrow from "../assets/menu_arrow";
import { useNavigate } from "react-router-dom";

interface PPurpleTextArrow {
  text: string;
  link?: string;
}

function CPurpleTextArrow({ text, link }: PPurpleTextArrow) {
  const navigate = useNavigate();

  return (
    <div
      className="text-arrow-div"
      onClick={() => {
        if (link) {
          navigate(link);
        }
      }}
    >
      <div className="text-16-24-600">{text}</div>
      <MenuArrow />
    </div>
  );
}

export default CPurpleTextArrow;
