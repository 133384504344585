import {baseUrlV2, getUserToken, getV2, postV2, userInstance} from "../utils/request";
import {FilterConfig, JobFilterType} from "../types/TFilter";
import {TError} from "../types/TError";
import {parseProjectDetail, TProject} from "../types/TProject";
import {NotificationType, ShowToast} from "../components/Toast/Toast";
import {enqueueSnackbar} from "notistack";
import {JobStatusInt} from "../types/TJob";

interface IProps {
  key?: string,
  profession?: number[],
  skill?: number[],
  workingPriceType?: number[],
  workingType?: number[],
  language?: number[],
  company?: string,
  country?: number,
  city?: number,
  priceBegin?: number,
  priceEnd?: number,
  currencyID?: number,
  deliveryDateType?: number,
  deliveryValue?: number,
  pageSize: number,
  pageNumber: number,
  hasFavorite?: boolean
}

export enum OrderType {
  BestMatch,
  Newest,
  Liked
}

export function FilterProjects(filters: Map<string, FilterConfig[]>, pageNumber: number, pageSize: number, orderType: OrderType, textFilter?: string) {
  // const url = baseUrlV2 + (orderType==OrderType.Liked?"/Favorite/list":"/Project/filterbypaging");
  const url = baseUrlV2 + ("/Project/filterbypaging");

  const requestProps: IProps = {
    pageSize: pageSize,
    pageNumber: pageNumber - 1,
  }

  if (orderType == OrderType.Liked) {
    requestProps.hasFavorite = true
  }
  if (textFilter != undefined && textFilter.length > 0) {
    requestProps.key = textFilter
  } else {
    if (filters.has(JobFilterType.Profession)) {
      const filterResponse = filters.get(JobFilterType.Profession)
      if (filterResponse != undefined) {
        requestProps.profession = filterResponse.map((f) => f.id)
      }
    }
    if (filters.has(JobFilterType.PaymentRange)) {
      const paymentFilters = filters.get(JobFilterType.PaymentRange)
      let length = paymentFilters?.length ?? 0;
      if (length > 0) {
        requestProps.priceBegin = paymentFilters![0].valueNumber
      }
      if (length > 1) {
        requestProps.priceEnd = paymentFilters![1].valueNumber
      }
      if (length > 2) {
        requestProps.currencyID = paymentFilters![2].id
      }
    }
    if (filters.has(JobFilterType.PaymentType)) {
      const filter = filters.get(JobFilterType.PaymentType)
      if (filter) {
        requestProps.workingPriceType = filter.map((f) => {
          return f.id
        })
      }
    }
    if (filters.has(JobFilterType.Skill)) {
      const filter = filters.get(JobFilterType.Skill)
      if (filter) {
        requestProps.skill = filter.map((f) => {
          return f.id
        })
      }
    }
    if (filters.has(JobFilterType.Location)) {
      const filter = filters.get(JobFilterType.Location)
      if (filter) {
        if (filter.length > 0) {
          requestProps.country = filter[0].id
          if (filter.length > 1) {
            requestProps.city = filter[1].id
          }
        }
      }
    }
    if (filters.has(JobFilterType.Language)) {
      const filter = filters.get(JobFilterType.Language)
      if (filter) {
        requestProps.language = filter.map((f) => {
          return f.id
        })
      }
    }
    if (filters.has(JobFilterType.WorkType)) {
      const filter = filters.get(JobFilterType.WorkType)
      if (filter) {
        requestProps.workingType = filter.map((f) => {
          return f.id
        })
      }
    }
  }

  const t = getUserToken()
  return userInstance
    .post(
      url,
      requestProps,
      {headers: {Authorization: `Bearer ${t}`}}
    )
    .then((data) => {
      if (data.data) {
        const Data = data.data;
        if (Data.result == false || Data.message != undefined) {
          ShowToast(
            enqueueSnackbar,
            NotificationType.Fail,
            'filterbypaging'
          );
          return false;
        }
        return Data;
      }
      return false;
    })
    .catch((ex) => {
      ShowToast(
        enqueueSnackbar,
        NotificationType.Fail,
        'filterbypaging'
      );
      return false;
    });
}

export async function GetProjectDetail(key: string): Promise<TError | TProject> {
  return getV2('/Project/getdata?projectKeyID=' + key).then(resp => {
    if (resp instanceof TError) {
      return resp
    }
    const r = resp.data
    if (r.result == false) {
      return new TError(0, r.message);
    }
    return parseProjectDetail(r, key)
  })
}

export async function updateOffer(projectKeyID?: string, currencyID?: number, price?: number, remark?: string, isProfessional?: boolean): Promise<TError | boolean> {
  let updateData = {
    projectKeyID: projectKeyID,
    currencyID: currencyID,
    price: price,
    status: isProfessional ? JobStatusInt.ProfessionalUpdateOffer : JobStatusInt.CompanyUpdateOffer,
    remark: remark,
    professionalRemark: isProfessional ? remark : undefined,
    companyRemark: !isProfessional ? remark : undefined,
    visible: true
  };
  return postV2('/ProjectOffer/update', updateData).then(r => {
    if (r instanceof TError) {
      if (r.errorMessage == 'Eski kayda ulaşılamıyor.') {
        return postV2('/ProjectOffer/create', updateData).then(r => {
          if (r instanceof TError) {
          debugger
            return r
          } else {
            return true
          }
        })
      }
      return r
    } else {
      return true
    }
  })
}
