import {FilterModal} from "./FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {FilterConfig, FilterPageProps, JobFilterType} from "../../types/TFilter";
import './Filter.scss'
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {getV2} from "../../utils/request";
import {TError} from "../../types/TError";
import {getWorkingPriceTypes} from "../../service/commonRequest";

const jobFilterType = JobFilterType.PaymentType;

export function FilterPaymentType({isOpen, onClose, onSave, filters}: FilterPageProps) {
  const mainContext = useContext(MainContext);

  const [options, setOptions] = useState<FilterConfig[] | undefined>(undefined)

  const [tempVal, setTempVal] = useState<FilterConfig[] | undefined>(undefined)

  useEffect(() => {
    if (mainContext?.userToken) {
      getWorkingPriceTypes(mainContext).then((resp) => {
        if (resp instanceof TError) {

        } else {
          setOptions(resp);
        }
      });
    }
  }, [mainContext?.userToken]);

  function handleSave() {
    onSave(tempVal)
    onClose();
  }

  function onCancel() {
    onClose();
  }

  useEffect(() => {
    if (filters) {
      if (filters.length > 0) {
        setTempVal(filters)
      }
    } else {
      setTempVal(undefined)
    }
  }, [filters]);

  return <FilterModal
    title={mainContext!.getTranslation('filters', jobFilterType)}
    header={mainContext!.getTranslation('filters', 'hangisini-tercih-edersin')}
    desc={mainContext!.getTranslation('filters', 'teslim-suresine-gore-secim')}
    isOpen={isOpen} onClose={onCancel} onSave={handleSave}>
    <CSelectWithSearchForFilter options={options} val={tempVal} isMultiple={true} loading={options == undefined}
      //@ts-ignore
                                onChange={setTempVal}
    />
  </FilterModal>
}
