import {MainContext} from "../../context/MainContext";
import {useContext, useEffect, useState} from "react";
import {UserType} from "../../types/TUserData";
import {Professional} from "../../components/Professional/Professional";
import {Company} from "../../components/Company/Company";
import CLoadingAnimation from "../../components/CLoadingAnimation";
import {useParams} from "react-router";
import {PageState} from "../../types/Context";

export interface UserQuery {
  type: UserType,
  keyId: string
}

export function Profile() {
  const mainContext = useContext(MainContext)

  const [isFinished, setIsFinished] = useState<boolean | UserQuery>(false)

  const params = useParams()

  useEffect(() => {
    if (mainContext?.userData) {
      if (!params.type || !params.id) {
        setIsFinished(true)
      } else {
        let keyId = params.id
        if (params.id2) {
          keyId = keyId + '/' + params.id2
        }
        if (params.id3) {
          keyId = keyId + '/' + params.id3
        }
        if (params.id4) {
          keyId = keyId + '/' + params.id4
        }
        if (params.id5) {
          keyId = keyId + '/' + params.id5
        }

        setIsFinished({type: params.type as unknown as UserType, keyId: keyId})
      }
    }
    if (localStorage.getItem("userData") == null && (mainContext?.userData == undefined || mainContext?.isExpired('profile'))) {
      setTimeout(() => {
        mainContext?.setSigninupState(PageState.Signin)
      }, 1500)
    }
  }, [params, mainContext, mainContext?.userData]);

  return (
    <>
      {(isFinished == false || (mainContext?.userData ?? undefined) == undefined) ?
        <CLoadingAnimation fullscreen/> :
        isFinished === true ? (
          mainContext!.userData!.userType == UserType.Professional ?
            <Professional/> : <Company/>
        ) : (
          isFinished.type == UserType.Professional ? <Professional userQuery={isFinished}/> :
            <Company userQuery={isFinished}/>
        )
      }
    </>
  )

}
