import {TMediaTextCardsRow} from "../types/TComponents";
import CMediaTextCard from "./CMediaTextCard";
import {isDesktop} from '../utils/window';
import CSwiper1Partner from "./CSwiper1Partner";

interface PPartnersRow {
  data: TMediaTextCardsRow;
}

function CPartnersRow({data}: PPartnersRow) {
  return (
    <div className={`flex justify-between gap-[40px] pt-[48px] ${data.parentClass}`}>
      {isDesktop() ? data.data.map((card, index) => {
          return <CMediaTextCard key={card.header + index} data={card} topComponentClass="h-[152px]"
                                 textClass="pt-[16px] pb-[24px] gap-[8px]"/>;
        }) :
        <CSwiper1Partner
          data={{
            cards: data.data.map((card, i) => {
              return (
                <CMediaTextCard key={card.header + i} data={card} topComponentClass="h-[152px]"
                                textClass="pt-[16px] pb-[16px] gap-[8px]"/>
              );
            }),
          }}
        />}
    </div>
  );
}

export default CPartnersRow;
