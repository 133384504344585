import {useEffect, useState} from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {Swiper, SwiperSlide} from "swiper/react";
import {TSwiper3Loop} from "../types/TComponents";

interface PSwiper3Loop {
  data: TSwiper3Loop;
  notActiveEffect?: boolean;
  swipeAmount?: number;
}

function CSwiper3Loop({data, notActiveEffect, swipeAmount}: PSwiper3Loop) {
  const [swiper, setSwiper] = useState<any | undefined>(undefined);

  const [canGoPrev, setCanGoPrev] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(1, 0);
    }
  }, [swiper]);

  const customArrow = <img src="/images/leftarrow.svg"/>

  return (
    <div className="w-full flex items-center">
      <div className={`w-[24px]`}>
        {canGoPrev && (
          <div
            onClick={() => {
              if (swipeAmount) {
                swiper.slideTo(swiper.activeIndex - swipeAmount, 600);
              } else {
                swiper.slidePrev(300);
              }
            }}
            className="swiper_arrow"
          >
            {customArrow}
          </div>
        )}
      </div>
      <Swiper
        slidesPerView={3}
        centeredSlides={true}
        className={`swiperMini ${notActiveEffect ? "notActiveEffect" : ""}`}
        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
        onSlideChange={(swiper) => {
          setCanGoPrev(!swiper.isBeginning);
          setCanGoNext(!swiper.isEnd);
        }}
      >
        {data.cards.map((card) => {
          return (
            <SwiperSlide>
              <div className="contentHolder !px-0">{card}</div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={`w-[24px] flex justify-end`}>
        {canGoNext && (
          <div
            onClick={() => {
              if (swipeAmount) {
                swiper.slideTo(swiper.activeIndex + swipeAmount, 600);
              } else {
                swiper.slideNext(300);
              }
            }}
            className="swiper_arrow"
            style={{transform: "rotate(180deg)"}}
          >
            {customArrow}
          </div>
        )}
      </div>
    </div>
  );
}

export default CSwiper3Loop;
