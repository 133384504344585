import {FilterModal} from "./FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {FilterConfig, FilterPageProps, JobFilterType} from "../../types/TFilter";
import './Filter.scss'
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {getV2} from "../../utils/request";
import {TError} from "../../types/TError";

const jobFilterType = JobFilterType.WorkType;

export function FilterWorkType({isOpen, onClose, onSave, filters}: FilterPageProps) {
  const mainContext = useContext(MainContext);

  const [options, setOptions] = useState<FilterConfig[] | undefined>(undefined)
  const [tempVal, setTempVal] = useState<FilterConfig[] | undefined>(undefined)

  useEffect(() => {
    if (mainContext?.userData?.token) {
      getV2(`/Common/workingtype/list`).then((resp) => {
        if (resp instanceof TError) {
          return
        }
        setOptions(
          resp.data.map((item: any) => {
            return {id: item.id, label: item.name};
          })
        );
      });
    }
  }, [mainContext?.userData?.token]);


  function handleSave() {
    onSave(tempVal)
    onClose();
  }

  useEffect(() => {
    if (filters) {
      if (filters.length > 0) {
        setTempVal(filters)
      }
    } else {
      setTempVal(undefined)
    }
  }, [filters]);

  return <FilterModal
    title={mainContext!.getTranslation('filters', jobFilterType)}
    header={mainContext!.getTranslation('filters', 'hangisini-tercih-edersin')}
    isOpen={isOpen} onClose={onClose} onSave={handleSave}>
    <CSelectWithSearchForFilter loading={options == undefined} isMultiple={true} options={options} val={tempVal}
      //@ts-ignore
                                onChange={setTempVal}/>
  </FilterModal>
}
