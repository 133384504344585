export interface TMediaObject {
  key:string
  url:string
  type:string
}

export function parseToMediaObject(c: any): TMediaObject {
  return {
    key: c.fileKey,
    url: c.fileUrl,
    type:c.type,
  }
}


export function parseMedioObjectToRequest(obj: TMediaObject,Type:string): any {
  return {fileKey: obj.key,fileUrl:obj.url,type:Type}
}
