import {useContext, useEffect, useState} from "react";
import CLoadingAnimation from "../components/CLoadingAnimation";
import {useNavigate, useSearchParams} from "react-router-dom";
import {activateRequest,} from "src/utils/request";
import {MainContext} from "../context/MainContext";
import {NotificationType, ShowToast} from "../components/Toast/Toast";
import {enqueueSnackbar} from "notistack";
import {TError} from "../types/TError";
import {CSuccessPopup} from "../components/CSuccessPopup";
import {PageState} from "../types/Context";

export function RegisterSuccess() {
  const mainContext = useContext(MainContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isSuccessAndText, setIsSuccessAndText] = useState<false|string>(false)
  useEffect(() => {
    if (mainContext && mainContext?.translationsLoaded && (mainContext!.publicToken?.length ?? 0) > 0 && isSuccessAndText==false) {
      const url = (window.location.pathname + window.location.search)
        // .replace('RegisterActivation','registeractivation')
        .replace('/tr','')
      // setIsSuccess(true);
      activateRequest(url).then((result) => {
        if (result instanceof TError) {
          ShowToast(
            enqueueSnackbar,
            NotificationType.Fail,
            'RegisterActivation'
          );
        } else {
          setIsSuccessAndText(result);
        }
      })
    }
  }, [mainContext,mainContext?.translationsLoaded, mainContext?.publicToken,isSuccessAndText]);

  return (
    <div className="h-[100vh] w-[100vw]">
      <CLoadingAnimation fullscreen={true}/>
      {(isSuccessAndText!=false) &&
          <CSuccessPopup text={mainContext?.getTranslation('giris-kayit','kayit-basarili')??''} buttonText={mainContext?.getTranslation('giris-kayit','giris-yap')??''} descText={isSuccessAndText} onClose={() => {
            mainContext?.setSigninupState(PageState.Signin)
            navigate('/')
          }}/>
      }
    </div>
  );
}
