import {useContext, useEffect, useState} from "react";
import CLoadingAnimation from "../components/CLoadingAnimation";
import {useSearchParams, useNavigate} from "react-router-dom";
import {
  loginWgoogleLinkSuccess,
  loginWlinkedinLinkSuccess,
} from "src/utils/request";
import {MainContext} from "../context/MainContext";
import {TError} from "../types/TError";
import {NotificationType} from "../components/Toast/Toast";
import {TUserData} from "../types/TUserData";

export function LoginSuccess() {
  const mainContext = useContext(MainContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loginHandled, setLoginHandled] = useState(false)
  useEffect(() => {
    if (loginHandled === true) {
      return
    }
    if (mainContext && (mainContext!.publicToken?.length ?? 0) > 0) {
      setLoginHandled(true)
      const loginType = localStorage.getItem("login");
      const successCode = searchParams.get("code");
      let loginFunc: (code: string) => Promise<TUserData | TError>
      if (loginType == "google") {
        loginFunc = loginWgoogleLinkSuccess
      } else if (loginType == "linkedin") {
        loginFunc = loginWlinkedinLinkSuccess
      }
      loginFunc!(successCode ?? "").then((response) => {
        if (response instanceof TError) {
          mainContext?.showToast(NotificationType.Fail, response.errorMessage, true)
        } else {
          console.log('2')
          mainContext?.setUserData(response)
          navigate('/dashboard')
        }
      })
    }
  }, [mainContext, mainContext?.publicToken, loginHandled]);

  return (
    <div className="h-[100vh] w-[100vw]">
      <CLoadingAnimation fullscreen={true}/>
    </div>
  );
}
