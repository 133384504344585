import {FilterModal} from "../Filter/FilterModal";
import 'dayjs/locale/tr';
import dayjs from "dayjs";
import {useContext, useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateIcon} from "../../utils/mui";
import {MainContext} from "../../context/MainContext";
import {FilterConfig} from "../../types/TFilter";
import {postV2} from "../../utils/request";
import {TError} from "../../types/TError";
import {getUniversityDepartments, highschools, universities} from "../../service/commonRequest";
import {CSelectWithSearchForFilterSingle} from "../Input/CSelectWithSearchForFilterSingle";
import {EducationType, EducationTypeOptions, TEducation} from "../../types/TEducation";
import CLoadingAnimation from "../CLoadingAnimation";
import {monthStringFromBackend} from "../../utils/date";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  editData: TEducation
  close: () => void
  updated: () => void
}

export function CEducationAddModal({getTranslation, isOpen, close, updated, editData}: IProps) {
  let now = dayjs();
  const mainContext = useContext(MainContext)

  const [startDate, setStartDate] = useState<dayjs.Dayjs | undefined>(undefined)
  const [endDate, setEndDate] = useState<dayjs.Dayjs | undefined>(undefined)
  const [startDateRef, setStartDateRef] = useState<any | undefined>(undefined)
  const [endDateRef, setEndDateRef] = useState<any | undefined>(undefined)
  const [educationType, setEducationType] = useState<FilterConfig | undefined>(undefined)
  const [school, setSchool] = useState<FilterConfig | undefined>(undefined)
  const [schoolName, setSchoolName] = useState('')
  const [manualSchool, setManualSchool] = useState(false)
  const [loading, setLoading] = useState(false)
  const [department, setDepartment] = useState<FilterConfig | undefined>(undefined)
  const [universityDepartments, setUniversityDepartments] = useState<FilterConfig[] | undefined>(undefined)
  const [requesting, setRequesting] = useState(false)

  const departmentMustSelect = educationType != undefined && educationType?.valueStr != EducationType.HighSchool

  const educationTypes: FilterConfig[] = EducationTypeOptions.map((e, i) => {
    return {id: i, valueStr: e, label: mainContext?.getBackendTranslation(e) ?? ''}
  })

  function onClose(){
    setStartDate(undefined)
    setEndDate(undefined)
    setEducationType(undefined)
    setSchool(undefined)
    setSchoolName('')
    setManualSchool(false)
    setDepartment(undefined)
    setUniversityDepartments(undefined)
    close();
  }

  useEffect(() => {
    if (isOpen === true) {
      setLoading(true)
      getUniversityDepartments(0, mainContext).then((r) => {
        setLoading(false)
        if (r instanceof TError) {
          setUniversityDepartments([])
        } else {
          setUniversityDepartments(r)

          if (editData) {
            let Type: FilterConfig | undefined = undefined

            for (let i = 0; i < educationTypes.length; i++) {
              if (educationTypes[i].valueStr == editData.educationType) {
                Type = educationTypes[i]
                setEducationType(Type)
                break;
              }
            }

            if (Type) {
              if (editData.schoolId > 0) {
                const tip = (Type.valueStr == EducationType.HighSchool ? highschools : universities)
                for (let i = 0; i < tip.length; i++) {
                  if (tip[i].id == editData.schoolId) {
                    setSchool(tip[i])
                  }
                }
              } else if (editData.schoolName.length > 0) {
                setSchoolName(editData.schoolName)
              }

              if (editData.departmentId > 0 && r && r.length > 0) {
                for (let i = 0; i < r.length; i++) {
                  if (r[i].id == editData.departmentId) {
                    setDepartment(r[i])
                    break;
                  }
                }
              }
            }

            setStartDate(dayjs(editData.beginYear + '-' + monthStringFromBackend(editData.beginMonth) + '-01T00:00:00.000Z'))
            setEndDate(dayjs(editData.endYear + '-' + monthStringFromBackend(editData.endMonth) + '-01T00:00:00.000Z'))
          }

        }
      })
    }
  }, [isOpen]);

  function save() {
    setRequesting(true)
    let data = {
      key: '',
      relationType: educationType?.id ?? 0,
      relationID: 0,
      relationItemID: 0,
      otherRelation: "",
      otherRelationItem: "",
      score: 0,
      remark: "",
      beginMonth: (startDate?.month() ?? 0) + 1,
      beginYear: startDate?.year() ?? 0,
      endMonth: (endDate?.month() ?? 0) + 1,
      endYear: endDate?.year() ?? 0,
    };

    if (school) {
      data.relationID = school.id
    } else {
      data.otherRelation = schoolName
    }
    if (departmentMustSelect) {
      if (department) {
        data.relationItemID = department.id
      }
    }

    let url = '/ProfessionalEducation/create';
    if (editData) {
      data.key = editData.id
      url = '/ProfessionalEducation/update'
    }
    postV2(url, data).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        onClose()
        updated()
      }
    }).catch(e => {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    }).finally(() => {
      setRequesting(false)
    })
  }

  useEffect(() => {
    if (startDate != undefined && endDate != undefined) {
      if (!endDate.isAfter(startDate)) {
        setEndDate(startDate)
      }
    }
  }, [startDate]);

  function SetEducationType(val: FilterConfig) {
    setEducationType(val)
    setSchool(undefined)
    setSchoolName('')
    setDepartment(undefined)
  }

  function SetSchool(val?: FilterConfig) {
    setSchool(val)
    if (val) {
      setDepartment(undefined)
    } else {
      setDepartment(undefined)
    }
    if (val && educationType?.valueStr == EducationType.University) {
      // setLoading(true)
      // getUniversityDepartments(val.id).then((r) => {
      //   setLoading(false)
      //   if(r instanceof TError){
      //     alert('fail')
      //     setUniversityDepartments([])
      //   }else {
      //     setUniversityDepartments(r)
      //   }
      // })
    }
  }

  const isUpdatable = (!manualSchool || schoolName && schoolName.length > 3) &&
    (!departmentMustSelect || department) && educationType && startDate && endDate

  return <FilterModal
    title={mainContext?.getTranslation('filters', 'job-education') ?? ''}
    header={''}
    isOpen={isOpen}
    onClose={onClose}
    onSave={save}
    requesting={requesting}
    saveDisabled={requesting || !isUpdatable}
    closeDisabled={requesting || !isUpdatable}
    customHeaderClass={'!py-[16px]'}
  >
    <div className={'relative'}>
      {loading &&
          <div className={'absolute h-full w-full flex z-[9999]'}>
              <div className={'h-full w-full flex flex-col justify-center items-center bg-[#fff]'}>
                  <CLoadingAnimation size={120}/>
              </div>
          </div>
      }
      <div className={'modalTitle pb-[16px]'}>{mainContext?.getTranslation('profile', 'derece')}</div>
      <div className={'w-full'}>
        <CSelectWithSearchForFilterSingle options={educationTypes} val={educationType} loading={false}
                                          customPortal={true}
          //@ts-ignore
                                          onChange={SetEducationType}
        />

        <div className={'modalTitle py-[16px]'}>{mainContext?.getTranslation('profile', 'okul')}</div>
        {manualSchool ?
          <>
            <input onChange={(e) => setSchoolName(e.target.value.trim())}
                   placeholder={mainContext?.getTranslation('common', 'giriniz')}
                   value={schoolName} className={'standardInput !color-[#6D6D6D]'}/>
            <div role={'button'}
                 className={'mt-[12px] text-purple text-14-16-600'}
                 onClick={() => setManualSchool(false)}>{`${mainContext?.getTranslation("common", "veya")} ${mainContext?.getTranslation("common", "listeden-sec-tikla")}`}</div>
          </>
          :
          <CSelectWithSearchForFilterSingle val={school} loading={false}
                                            disabled={educationType == undefined}
                                            options={educationType == undefined ? [] : (educationType.valueStr == EducationType.HighSchool ? highschools : universities)}
            //@ts-ignore
                                            onChange={SetSchool}
                                            noResultHandler={<div role={'button'}
                                                                  className={'text-purple text-14-16-600'}
                                                                  onClick={() => setManualSchool(true)}>{mainContext?.getTranslation("common", "bulamadiniz-mi-elle-girin")}</div>}
          />
        }

        {departmentMustSelect && <>
            <div className={'modalTitle py-[16px]'}>{mainContext?.getTranslation('profile', 'bolum')}</div>
            <CSelectWithSearchForFilterSingle options={universityDepartments} val={department} loading={false}
              // disabled={school == undefined}
                                              disabled={school == undefined && manualSchool == false}
              //@ts-ignore
                                              onChange={setDepartment}
            />
        </>}

        <div className={'modalTitle pt-[32px]'}>{mainContext?.getTranslation('profile', 'tarih')}</div>
        <div className={'flex gap-[28px] w-full py-[16px]'}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>
            <DatePicker className={'dateInput flex-1'} views={['year', 'month']}
                        value={startDate}
                        onAccept={(e) => {
                          setStartDate(e ?? undefined)
                        }}
                        components={{
                          OpenPickerIcon: DateIcon
                        }}
                        slotProps={{
                          popper: {placement: 'top'},
                          textField: {
                            onKeyDown: (e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            },
                            placeholder: 'Seçiniz', onClick: () => {
                              const founds = startDateRef?.getElementsByClassName('MuiIconButton-edgeEnd')
                              if (founds != undefined) {
                                founds[0].click();
                              }
                            }
                          }
                        }}
                        ref={(c) => {
                          setStartDateRef(c)
                        }}/>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>
            <DatePicker className={'dateInput flex-1'} views={['year', 'month']}
                        value={endDate}
                        onAccept={(e) => {
                          setEndDate(e ?? undefined)
                        }}
                        minDate={startDate == undefined ? undefined : startDate.add(1, 'month')}
                        components={{
                          OpenPickerIcon: DateIcon
                        }}
                        slotProps={{
                          popper: {placement: 'top'},
                          textField: {
                            onKeyDown: (e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            },
                            placeholder: 'Seçiniz', onClick: () => {
                              const founds = endDateRef?.getElementsByClassName('MuiIconButton-edgeEnd')
                              if (founds != undefined) {
                                founds[0].click();
                              }
                            }
                          }
                        }}
                        ref={(c) => {
                          setEndDateRef(c)
                        }}/>
          </LocalizationProvider>
        </div>
      </div>

    </div>
  </FilterModal>
}
