export interface JobFilter {
  name: string;
  key: string;
  type: FilterType
}

export enum FilterType {
  Text,
  Range
}

export enum JobFilterType {
  PaymentType = "job-paymentType",
  PaymentRange = "job-paymentRange",
  ServiceOptions = "job-serviceOptions",
  DeliveryDate = "job-deliveryTime",
  Profession = "job-expertise",
  Skill = "job-talent",
  Language = "job-language",
  WorkType = "job-workType",
  Location = "job-location",
}

export function GetFilters(): JobFilterType[] {
  return [
    JobFilterType.PaymentType,
    JobFilterType.PaymentRange,
    JobFilterType.ServiceOptions,
    JobFilterType.DeliveryDate,
    JobFilterType.Profession,
    JobFilterType.Skill,
    JobFilterType.Language,
    JobFilterType.WorkType,
    JobFilterType.Location
  ]
}

export interface FilterConfig {
  id: number
  label: string
  code?: string
  symbol?: string
  valueStr?: string
  valueNumber?: number
}


export interface FilterPageProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (filters: FilterConfig[] | undefined) => void;
  filters?: FilterConfig[];
}
