
export interface IProps{
  header: string;
  sub: string;
  onClick?:()=>void;
}

function CSwiperTextCard({header, sub,onClick}:IProps) {
  return (
    <div role={'button'} onClick={onClick} className="flex-col justify-between h-full content">
      <div className="header text-18-24-600">{header}</div>
      <div className="sub text-16-24-500">{sub}</div>
    </div>
  );
}
export default CSwiperTextCard;
