export class RJob {
  name: string;
  workingTypeStr: string;

  constructor(name: string, workingTypeStr: string) {
    this.name = name;
    this.workingTypeStr = workingTypeStr;
  }

  static parse(data: any): RJob[] {
    const list = data.data.map((item: any) => {
      return new RJob(item.name, item.workingTypeStr);
    });
    return list;
  }
}

export class PartnerData {
  pictureName: string;

  constructor(pictureName: string) {
    this.pictureName = pictureName;
  }

  getUrl(): string {
    return "https://www.pofft.com/Upload/PagePicture/" + this.pictureName;
  }
}
