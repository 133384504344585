import {FilterModal} from "../Filter/FilterModal";
import 'dayjs/locale/tr';
import dayjs from "dayjs";
import {useContext, useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useDropzone} from "react-dropzone";
import {DateIcon} from "../../utils/mui";
import {MainContext} from "../../context/MainContext";
import {FilterConfig, JobFilterType} from "../../types/TFilter";
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {getV2, postV2} from "../../utils/request";
import {TError} from "../../types/TError";
import {EnumProfileUpdateType} from "../../types/EnumProfileUpdateType";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  filters?: FilterConfig[];
  selecteds?: string[];
  isOpen: boolean
  close: () => void
  onUpdated: (newSkills: string[]) => void
  options: FilterConfig[]
}

export function CProfileSkillsModal({isOpen, close, filters, selecteds, options, onUpdated}: IProps) {
  const mainContext = useContext(MainContext);
  const [tempOptions, setTempOptions] = useState<FilterConfig[] | undefined>(undefined)
  const [tempVal, setTempVal] = useState<FilterConfig[] | undefined>(undefined)
  const [requesting, setRequesting] = useState(false)

  useEffect(() => {
    setTempOptions(options)
  }, [options]);

  function handleSave() {
    setRequesting(true)
    let skills = tempVal == undefined ? undefined : tempVal.map(t => "" + t.id);

    postV2('/Professional/partialupdate', {
      updateType: EnumProfileUpdateType.Skill,
      skills: skills,
    }).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        onUpdated(skills ?? []);
        close();
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    }).finally(() => {
      setRequesting(false)
    })
  }

  useEffect(() => {
    if (selecteds) {
      if (selecteds.length > 0) {
        let filtered = filters?.filter((f) => {
          const found = selecteds.find(s => s == f.id + "")
          return found
        });
        setTempVal(filtered)
      }
    } else {
      setTempVal(undefined)
    }
  }, [selecteds]);

  useEffect(() => {

    if (isOpen) {
      if (selecteds) {
        if (selecteds.length > 0) {
          let filtered = filters?.filter((f) => {
            const found = selecteds.find(s => s == f.id + "")
            return found
          });
          setTempVal(filtered)
        }
      } else {
        setTempVal(undefined)
      }
    }
  }, [isOpen]);

  return <FilterModal
    title={mainContext!.getTranslation('filters', JobFilterType.Skill)}
    header={mainContext!.getTranslation('filters', 'yeteneklerin')}
    desc={mainContext!.getTranslation('filters', 'bu-kisim-onemli')}
    requesting={requesting}
    closeDisabled={requesting}
    saveDisabled={requesting}
    isOpen={isOpen} onClose={close} onSave={handleSave}>
    <CSelectWithSearchForFilter loading={tempOptions == undefined} isMultiple={true} options={tempOptions} val={tempVal}
      //@ts-ignore
                                onChange={setTempVal}/>
  </FilterModal>
}
