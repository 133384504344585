import React, {useContext, useEffect, useState} from "react";
import {Autocomplete, Box, styled, TextField} from "@mui/material";
import {MainContext} from "../../context/MainContext";
import './Inputs.scss';
import {FilterConfig} from "../../types/TFilter";
import CLoadingAnimation from "../CLoadingAnimation";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from "prop-types";
import {autocompleteClasses} from '@mui/material/Autocomplete';
import {useAutocomplete} from "@mui/base";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;


interface IProps {
  val: any
  onChange: (newVals: FilterConfig | FilterConfig[] | undefined) => void
  isMultiple?: boolean
  disabled?: boolean
  loading?: boolean
  options?: FilterConfig[];
  customClass?: string;
  placeholder?: string;
}

const Root = styled('div')(
  ({theme}) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
  font-size: 14px;
`,
);

const Label = styled('label')`
    padding: 0 0 4px;
    line-height: 1.5;
    display: block;
`;

const InputWrapper = styled('div')(
  ({theme}) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

function Tag(props: any) {
  const {label, onDelete, ...other} = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete}/>
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({theme}) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

const Listbox = styled('ul')(
  ({theme}) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);


export function CSelectWithSearchForFilter({
                                             val,
                                             onChange,
                                             isMultiple,
                                             options,
                                             disabled,
                                             loading,
                                             placeholder,
                                             customClass
                                           }: IProps) {

  const mainContext = useContext(MainContext);

  // useEffect(() => {
  //   setSelectedSelectedValues(val);
  // }, [val]);

  function castOptions(vals: any) {
    if (vals == undefined) {
      return []
    }
    return vals.map((v: any) => {
      return {title: v.label, option: v}
    })
  }


  function castBackOptions(vals: any[]) {
    let mapped = vals.map((v) =>
      v.option
    );
    return mapped
  }


  const [selectedValues, setSelectedSelectedValues] = React.useState<any[]>(castOptions(val));

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    value: selectedValues ?? [],
    onChange: (event, newValue) => {
      setSelectedSelectedValues(newValue);
      onChange(castBackOptions(newValue));
    },
    multiple: isMultiple,
    options: options ? castOptions(options) : [],
    getOptionLabel: (option) => option ? option.title : undefined,
    disabled: disabled
  });

  const [inputVal, setInputVal] = useState('')

  useEffect(() => {
    setSelectedSelectedValues(castOptions(val))
  }, [val]);

  return <>
    {(loading != undefined && loading == true) ?
      <div className={'flex flex-col justify-center h-full'}>
        <CLoadingAnimation size={44}/>
      </div>
      :
      <div className={'input-select'}>
        <div {...getRootProps()}>
          <InputWrapper ref={setAnchorEl} className={`selectWrapper ${focused ? 'focused' : ''} ${customClass ?? ''}`}>
            {(selectedValues == undefined || selectedValues[0] == undefined) ? <></> : selectedValues.map((option, index) => {
              return <StyledTag label={option.title} {...getTagProps({index})} />
            })}
            {((selectedValues == undefined || selectedValues.length == 0 || selectedValues[0] == undefined) && inputVal.length == 0) &&
                <div style={{
                  pointerEvents: 'none',
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  top: 0,
                  bottom: 0,
                  height: '100%',
                  backgroundColor: 'white',
                  color: '#999999'
                }}>
                  {placeholder ?? mainContext!.getTranslation('common', 'seciniz')}
                </div>
            }
            <input {...getInputProps()} onChange={(e) => {
              setInputVal(e.target.value)
              //@ts-ignore
              getInputProps().onChange(e)
            }}/>
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()} onClick={(e) => {
            setInputVal('')
          }}>
            {/*@ts-ignore*/}
            {groupedOptions.map((option, index) => {

              let shouldExist = true
              if (selectedValues != undefined) {
                for (var i = 0; i < selectedValues.length; i++) {
                  //@ts-ignore
                  if (selectedValues[i].title == option.title) {
                    shouldExist = false
                    break
                  }
                }
              }
              if (shouldExist) {
                let a = option.title.toLocaleLowerCase('tr-TR');
                let b = inputVal.toLocaleLowerCase('tr-TR');

                a = a.replace('ı', 'i').replace('ş', 's').replace('ç', 'c').replace('ü', 'u').replace('ö', 'o')
                b = b.replace('ı', 'i').replace('ş', 's').replace('ç', 'c').replace('ü', 'u').replace('ö', 'o')

                if (inputVal.length > 0 && !a.includes(b)) {
                  shouldExist = false
                }
              }

              return shouldExist ? (
                //@ts-ignore
                <li {...getOptionProps({option, index})}>
                  {/*@ts-ignore*/}
                  <span>{option.title}</span>
                  <img style={{display: 'none'}} src={"/images/closeModal.svg"}/>
                </li>
              ) : <></>
            })}
          </Listbox>
        ) : null}

      </div>}
  </>
}
