import { Button } from "@mui/material";

const imgUrl = "https://www.pofft.com/Upload/PagePicture/20231003011722_2.jpg";

export function Experts({}: any) {
  return (
    <>
      <div className="experts">
        <div
          className="expert"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        >
          <div className="expert-detail">
            <div className="header">
              <h3 className="name">Melis Büğen</h3>
              <p className="score">
                <img src="/images/star-green.svg" alt="" />
                <p>
                  4.9 <span>(839)</span>{" "}
                </p>
              </p>
            </div>
            <h4 className="job">UI/UX Designer</h4>
          </div>
        </div>
        <div
          className="expert"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        >
          <div className="expert-detail">
            <div className="header">
              <h3 className="name">Melis Büğen</h3>
              <p className="score">
                <img src="/images/star-green.svg" alt="" />
                <p>
                  4.9 <span>(839)</span>{" "}
                </p>
              </p>
            </div>
            <h4 className="job">UI/UX Designer</h4>
          </div>
        </div>
        <div
          className="expert"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        >
          <div className="expert-detail">
            <div className="header">
              <h3 className="name">Melis Büğen</h3>
              <p className="score">
                <img src="/images/star-green.svg" alt="" />
                <p>
                  4.9 <span>(839)</span>{" "}
                </p>
              </p>
            </div>
            <h4 className="job">UI/UX Designer</h4>
          </div>
        </div>
        <div
          className="expert"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        >
          <div className="expert-detail">
            <div className="header">
              <h3 className="name">Melis Büğen</h3>
              <p className="score">
                <img src="/images/star-green.svg" alt="" />
                <p>
                  4.9 <span>(839)</span>{" "}
                </p>
              </p>
            </div>
            <h4 className="job">UI/UX Designer</h4>
          </div>
        </div>
        <div
          className="expert"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        >
          <div className="expert-detail">
            <div className="header">
              <h3 className="name">Melis Büğen</h3>
              <p className="score">
                <img src="/images/star-green.svg" alt="" />
                <p>
                  4.9 <span>(839)</span>{" "}
                </p>
              </p>
            </div>
            <h4 className="job">UI/UX Designer</h4>
          </div>
        </div>
        <div className="expert">
          <div className="expert-detail" style={{ backgroundColor: "unset" }}>
            <div className="expert-detail_end">
              <p className="description">
                Lorem ipsum dolor sit amet consectetur. Dui quam justo platea
                sit.
              </p>
              <Button className="purpleButtonMini" onClick={() => {}}>
                <span>Proje Yayınla</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
