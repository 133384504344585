import {ComponentShowModal} from "./ComponentShowModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import CLoadingAnimation from "../CLoadingAnimation";
import {useNavigate} from "react-router-dom";
import {TProject} from "../../types/TProject";
import {TProjectOffer} from "../../types/TProjectOffer";
import {currencies, getTranslations} from "../../service/commonRequest";
import {parsePrice} from "../../utils/currency";
import {Button, TextField} from "@mui/material";
import {priceInput_purple_border_more_round} from "../Numeric/PriceInput";
import {ModalButton} from "../Input/ModalButton";
import {postV2} from "../../utils/request";
import {NotificationType} from "../Toast/Toast";
import {isOfferableJobStatus, JobStatus, ProjectOfferUpdate} from "../../types/TJob";
import {requestPayment, TPaymentRequest} from "../../types/TPaymentRequest";
import {BModal} from "../Modal/BModal";
import styles from "../../pages/ProjectDetail/ProjectDetail.module.scss";
import {TError} from "../../types/TError";

interface IProps {
  project: TProject
  onClose: (newStatus?: ProjectOfferUpdate) => void
  offer?: TProjectOffer
  projectTranslations: (key: string) => string
  projectState?: JobStatus
}

var interval: any = undefined;

export default function CProjectOfferByCompanyModal({
                                                      projectTranslations,
                                                      onClose,
                                                      offer,
                                                      project,
                                                      projectState
                                                    }: IProps) {
  const mainContext = useContext(MainContext)

  const [isLoading, setIsLoading] = useState(false)
  const [newOfferPrice, setNewOfferPrice] = useState(0.0)
  const [companyMessage, setCompanyMessage] = useState('')
  const [loadingOffer, setLoadingOffer] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [loadingDecline, setLoadingDecline] = useState(false)
  const [paymentRequest, setPaymentRequest] = useState<undefined | TPaymentRequest>(undefined)
  const [paymentLoaded, setPaymentLoaded] = useState(false)

  const approvablePrice = (() => {
    if (offer == undefined) {
      return undefined
    }

    let os = offer.statusEnum ?? ProjectOfferUpdate.Waiting;
    if (os == ProjectOfferUpdate.CompanyUpdateOffer)
      return offer.companyRequestPrice
    else if (os == ProjectOfferUpdate.ProfessionalUpdateOffer) {
      return offer.price
    }

    return undefined
  })()

  const isOpen = offer != undefined
  const offerNewPriceDisabled = approvablePrice == undefined || (offer?.price ?? 0) == 0 || (offer?.price ?? 0) == newOfferPrice || (offer?.companyRequestPrice ?? 0) == newOfferPrice
  const offerNewMessageDisabled = companyMessage.length == 0 || companyMessage == offer?.companyRemark

  useEffect(() => {
    if (isOpen) {
      setNewOfferPrice((offer?.price ?? 0))
    }
  }, [isOpen]);

  const navigate = useNavigate()
  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOfferPrice(parseFloat(event.target.value));
  };

  function makeOfferForCompany() {
    if (project && offer) {
      let data = {
        projectKeyID: project.projectKeyID,
        professinalKeyID: offer.professionalKeyId,
        companyRequestPrice: newOfferPrice,
        status: ProjectOfferUpdate.CompanyUpdateOffer,
        companyRemark: companyMessage,
      };
      setLoadingOffer(true)
      postV2('/ProjectOffer/update', data).then(r => {
        if (r.data.result == true) {
          mainContext?.showToast(NotificationType.Success, 'updateOffer')
          onClose(data.status)
        } else {
          mainContext?.showToast(NotificationType.Fail, 'updateOffer')
        }
      }).finally(() => {
        setLoadingOffer(false)
      })
    }
  }

  const [beforeApprove, setBeforeApprove] = useState(false)

  function BeforeApprove() {
    setBeforeApprove(true);
  }

  function CancelApprove() {
    setBeforeApprove(false);
  }

  function approve() {
    CancelApprove();
    if (project && offer) {
      let data = {
        projectKeyID: project.projectKeyID,
        professinalKeyID: offer.professionalKeyId,
        status: ProjectOfferUpdate.Confirmed,
        companyRequestPrice: newOfferPrice,
        price: newOfferPrice,
      };
      setLoadingApprove(true)
      postV2('/ProjectOffer/update', data).then(r => {
        if(r instanceof TError) {
          mainContext?.showToast(NotificationType.Fail, 'updateOffer')
          return
        }
          mainContext?.showToast(NotificationType.Success, 'updateOffer')
          onClose(data.status)
      }).finally(() => {
        setLoadingApprove(false)
      })
    }
  }

  function decline() {
    if (project && offer) {
      let data = {
        projectKeyID: project.projectKeyID,
        professinalKeyID: offer.professionalKeyId,
        status: ProjectOfferUpdate.Refuse
      };
      setLoadingDecline(true)
      postV2('/ProjectOffer/update', data).then(r => {
        if (r.data.result == true) {
          mainContext?.showToast(NotificationType.Success, 'updateOffer')
          onClose(data.status)
        } else {
          mainContext?.showToast(NotificationType.Fail, 'updateOffer')
        }
      }).finally(() => {
        setLoadingDecline(false)
      })
    }
  }

  function makePayment() {
    requestPayment(offer!.offerKeyId, mainContext).then(r => {
      if (r == false) {
        return
      }
      setPaymentRequest(r)
      const script = document.createElement('script')
      script.id = 'paymentModal'
      script.textContent = r.checkoutFormContent
      script.type = 'text/javascript'
      document.body.appendChild(script)
    })
  }

  useEffect(() => {
    if (paymentRequest != undefined) {
      interval = setInterval(() => {
        const elements = document.getElementsByClassName(mainContext?.getTranslation('backend', 'iyzicoClass') ?? 'iyzicoClass');
        if (elements.length > 0) {
          setPaymentLoaded(true)
        }
      }, 1000)
    }

    if (paymentLoaded) {
      interval = setInterval(() => {
        const elements = document.getElementsByClassName(mainContext?.getTranslation('backend', 'iyzicoClass') ?? 'iyzicoClass');
        if (elements.length == 0) {
          // setPaymentLoaded(false)
          // setPaymentRequest(undefined)
          clearInterval(interval)
          interval = undefined
          window.location.reload()
          // document.getElementById('paymentModal')?.remove();
        }
      }, 1000)
    }

  }, [paymentLoaded, paymentRequest]);

  function acceptProfessionalPrice() {
    setNewOfferPrice(offer!.price)
  }

  return <>
    <ComponentShowModal onClose={onClose}
                        rightSpacing={18}
                        title={`${projectTranslations('gelen-teklif')}`}
                        componentClass={'!pr-[18px]'}
                        component={
                          isOpen ? <div className={'w-full pt-0 pb-[40px] flex-col'}>
                            {isLoading ? <CLoadingAnimation size={100}/> :
                              <div className={'flex-col pt-[20px]'}>

                                <div className={'flex pb-[32px] justify-between'}>
                                  <div className={'flex'}>
                                    <div
                                      className={'div-lightest-purple p-[10px] flex gap-[10px] rounded-[8px]'}>
                                     <span
                                       className={'text-purple text-14-24-700'}>{projectTranslations('proje-durumu')}:</span>
                                      <span
                                        className={'text-grey text-14-24-400'}>{mainContext?.getBackendTranslation((offer?.status ?? '') == 'CompanyUpdateOffer' ? 'offerupdated' : 'status_' + offer?.status)}</span>
                                    </div>
                                  </div>
                                  {offer?.professionalKeyId &&
                                      <div role={'button'}
                                           onClick={() => navigate(`/profile/Professional/${offer.professionalKeyId}`)}
                                           className={'div-lightest-purple p-[10px] flex gap-[10px] rounded-[8px]'}>
                                                     <span className={'text-purple text-14-24-700'}>
                                                       {projectTranslations('uzman-profili-incele')}
                                                     </span>
                                      </div>
                                  }
                                </div>

                                <div className={'flex-1 flex'}>
                                  <div className={'flex-col gap-[20px] justify-center w-full'}>
                                    <div className={'flex justify-between'}>
                                      <div className={'flex-col'}>
                                        <p
                                          className={'text-12-24-700'}>{projectTranslations('gelen-teklif-tutar')}</p>
                                        <div
                                          className={'w-[218px] rounded-[16px] border-purple py-[6px] px-[16px] flex justify-center'}>
                                          <p className={'text-12-24-500 text-purple'}>
                                            {(offer.price && offer.price > 0) ?
                                              `${parsePrice(offer.price)} ${currencies.find(c => c.id == offer.currencyId)?.code}` :
                                              projectTranslations('teklif-bulunmamakta')}
                                          </p>
                                        </div>
                                      </div>
                                      <div className={'flex-col'}>
                                        <p
                                          className={'text-12-24-700'}>{projectTranslations('onerilen-teklif-tutari')}</p>
                                        <div
                                          className={'w-[218px] rounded-[16px] border-purple py-[6px] px-[16px] flex justify-center'}>
                                          <p className={'text-12-24-500 text-purple'}>
                                            {(offer?.companyRequestPrice && offer.companyRequestPrice > 0) ?
                                              `${parsePrice(offer?.companyRequestPrice)} ${currencies.find(c => c.id == offer.currencyId)?.code}` :
                                              projectTranslations('teklif-bulunmamakta')}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={'flex flex-col gap-[12px]'}>
                                      <p
                                        className={'text-12-24-700'}>{projectTranslations('uzman-proje-hakkinda-dusunceleri')}</p>
                                      <div
                                        className={'w-full rounded-[16px] border-purple p-[16px] flex justify-center'}>
                                        <p className={'text-12-24-500 text-black'}>
                                          {(offer?.professionalRemark ?? '').length == 0 ? mainContext?.getTranslation('common', 'girilmedi') : offer?.professionalRemark}
                                        </p>
                                      </div>
                                    </div>
                                    <div className={'flex flex-col gap-[12px]'}>
                                      <p
                                        className={'text-12-24-700'}>{projectTranslations('uzmana-mesajiniz')}</p>
                                      <div
                                        className={'w-full rounded-[16px] border-purple p-[16px] flex justify-center'}>
                                        <p className={'text-12-24-500 text-black'}>
                                          {(offer?.companyRemark ?? '').length == 0 ? mainContext?.getTranslation('common', 'girilmedi') : offer?.companyRemark}
                                        </p>
                                      </div>
                                    </div>
                                    {offer?.statusEnum != ProjectOfferUpdate.Confirmed &&
                                        <>
                                          {projectState && isOfferableJobStatus(projectState) &&
                                              <div className={'flex flex-col gap-[12px]'}>
                                                  <div className={'flex gap-[12px] items-center'}>
                                                      <p className={'text-12-24-700'}>
                                                        {projectTranslations('fiyat-oner')}
                                                      </p>
                                                    {offer.price > 0 && offer.companyRequestPrice > 0 && offer.price != offer.companyRequestPrice && newOfferPrice != offer.price &&
                                                        <p onClick={acceptProfessionalPrice} role={'button'}
                                                           className={'text-10-16-600 text-purple'}>
                                                          {projectTranslations('uzman-teklifini-kabul-et')}
                                                        </p>
                                                    }
                                                  </div>
                                                  <TextField
                                                      placeholder={mainContext?.getTranslation('common', 'giriniz')}
                                                      value={newOfferPrice}
                                                      onChange={handlePriceChange}
                                                      InputProps={{
                                                        inputComponent: priceInput_purple_border_more_round,
                                                        inputProps: {customSuffix: project.currency?.code ?? undefined}
                                                      }}
                                                      variant="outlined"
                                                  />
                                              </div>
                                          }

                                          {projectState && isOfferableJobStatus(projectState) &&
                                              <div className={'flex flex-col gap-[12px]'}>
                                                  <p className={'text-12-24-700'}>
                                                    {projectTranslations('mesaj-gonder')}
                                                  </p>
                                                  <TextField
                                                      className={'purple-border-input-round16'}
                                                      placeholder={mainContext?.getTranslation('common', 'giriniz')}
                                                      value={companyMessage}
                                                      onChange={(e) => setCompanyMessage(e.target.value)}
                                                      variant="outlined"
                                                  />
                                              </div>
                                          }

                                            <div className={'pt-[32px] flex justify-center gap-[24px]'}>
                                              {projectState && projectState == JobStatus.WaitingForPayment &&
                                                  <>
                                                      <ModalButton className={'div-green'}
                                                                   loading={loadingApprove}
                                                                   disabled={false}
                                                                   text={projectTranslations('odeme-yap')}
                                                                   onClick={() => {
                                                                     makePayment()
                                                                   }}/>
                                                      <ModalButton className={'div-light-red'}
                                                                   loading={loadingDecline}
                                                                   disabled={false}
                                                                   text={projectTranslations('teklifi-reddet')}
                                                                   onClick={() => {
                                                                     if (loadingApprove || loadingDecline || loadingOffer) return
                                                                     decline()
                                                                   }}/>
                                                  </>
                                              }
                                              {projectState && isOfferableJobStatus(projectState) &&
                                                  <>
                                                      <ModalButton className={'div-purple'}
                                                                   loading={loadingOffer}
                                                                   disabled={(offerNewMessageDisabled && offerNewPriceDisabled) || project.hasConfirmOffer}
                                                                   text={projectTranslations('teklifi-guncelle')}
                                                                   onClick={() => {
                                                                     if (offerNewPriceDisabled && offerNewMessageDisabled) return
                                                                     if (loadingApprove || loadingDecline || loadingOffer) return
                                                                     makeOfferForCompany()
                                                                   }}/>
                                                      <ModalButton className={'div-green'}
                                                                   loading={loadingApprove}
                                                                   disabled={newOfferPrice != offer.price || !offerNewPriceDisabled || project.hasConfirmOffer/* || (offer && (offer.statusEnum == ProjectOfferUpdate.Confirmed))*/}
                                                                   text={projectTranslations('onayla')}
                                                                   onClick={() => {
                                                                     if (!offerNewPriceDisabled) return
                                                                     if (loadingApprove || loadingDecline || loadingOffer) return
                                                                     BeforeApprove()
                                                                   }}/>
                                                      <ModalButton className={'div-light-red'}
                                                                   loading={loadingDecline}
                                                                   disabled={offer && (project.hasConfirmOffer || offer.statusEnum == ProjectOfferUpdate.Refuse /*|| offer.statusEnum == ProjectOfferUpdate.Confirmed*/)}
                                                                   text={projectTranslations('teklifi-reddet')}
                                                                   onClick={() => {
                                                                     if (loadingApprove || loadingDecline || loadingOffer) return
                                                                     decline()
                                                                   }}/>
                                                  </>
                                              }

                                            </div>
                                        </>
                                    }
                                  </div>
                                </div>
                              </div>
                            }
                          </div> : undefined
                        }/>
    <BModal isOpen={beforeApprove} onClose={() => CancelApprove()} title={''}
            children={<div className={'flex flex-col items-center pt-[24px]'}>
              <div className={'successTexts bigText'}>
                <div>
                  {projectTranslations('onaylamak-istediginize-emin-misiniz')}
                </div>
              </div>
              <div className={'pt-[41px] flex justify-center'}>
                <Button className={'purpleButtonMini !bg-[#05D490]'} onClick={approve}>
                  {projectTranslations('onayla')}
                </Button>
              </div>
            </div>}/>
    <ComponentShowModal onClose={() => setPaymentRequest(undefined)}
                        title={mainContext?.getTranslation('common', 'yukleniyor')}
                        parentClass={'!max-w-[300px]'}
                        component={(paymentRequest != undefined && !paymentLoaded) ?
                          <div className={'flex justify-center'}><CLoadingAnimation size={200}/></div> : undefined}
    />
  </>
}
