import {useContext, useState} from "react";
import {TMediaTextCard} from "../types/TComponents";
import {Box, Modal} from "@mui/material";
import {MainContext} from "../context/MainContext";
import {isDesktop} from "../utils/window";

interface PMediaTextCard {
  data: TMediaTextCard;
  parentClass?: string;
  topComponentClass?: string;
  textClass?: string;
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
}

function CMediaTextCard({
                          data,
                          parentClass,
                          topComponentClass,
                          textClass,
                          headerComponent,
                          footerComponent,
                        }: PMediaTextCard) {

  const mainContext = useContext(MainContext);
  const IsDesktop = isDesktop();

  const [playVideo, setPlayVideo] = useState(false);

  const [cominsoonTriggered, setCominsoonTriggered] = useState(false)

  return (
    <div
      className={`flex-1 overflow-hidden rounded-[16px] bg-white flex-col w-full ${
        parentClass ?? ""
      }`}
      style={{boxShadow: "0px 20px 80px #EBECF0"}}
      onMouseEnter={()=>{
        if(data.comingSoon && IsDesktop){
          setCominsoonTriggered(true)
        }
      }}
      onMouseLeave={()=>{
        if(data.comingSoon && IsDesktop){
          setCominsoonTriggered(false)
        }
      }}
      onClick={(e) => {
        if (data.comingSoon && data.comingSoon == true) {
          e.preventDefault();
          e.stopPropagation();
          setCominsoonTriggered(val => !val);
        }
      }}
    >
      {cominsoonTriggered ?
        <div className={'flex justify-center items-center p-[20px] h-full w-full bg-gray-200 text-20-24-600'}>
          <p className={'whitespace-break-spaces'}>{mainContext?.getTranslation('common', 'pek-yakinda').replace(" ", "\n")}</p>
        </div> : <>
          <div className={` ${topComponentClass ?? ""}`}>
            {data.topComponentImage && (
              <img
                onClick={() => {
                  if (data.onClick) {
                    data.onClick();
                  } else if (data.topComponentVideo) {
                    setPlayVideo(true);
                  }
                }}
                className="h-full object-cover w-full"
                src={data.topComponentImage}
              ></img>
            )}
            {headerComponent}
          </div>
          <div className={`flex-col justify-between ${textClass ?? ""}`} onClick={() => {
            if (data.onClick) {
              data.onClick();
            } else if (data.topComponentVideo) {
              setPlayVideo(true);
            }
          }}>
            <div className="text-16-24-600 lg:text-18-24-600 text-black text-new-lines"
                 dangerouslySetInnerHTML={{__html: data.header}}>
            </div>
            <div className="text-14-24-500 lg:text-16-24-600 text-light-grey text-new-lines px-[10px]">
              {data.sub}
            </div>
          </div>
          {footerComponent}
          <Modal
            open={playVideo}
            onClose={() => {
              setPlayVideo(false);
            }}
            // className="w-[54vw] h-[43vh] m-auto h-[439px] w-[780px]"
            className="m-auto lg:h-[439px] lg:w-[780px] lg:max-h-[439px] lg:max-w-[780px] max-w-[375px] max-h-[211px]"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <iframe
              id="video"
              width="100%"
              height="100%"
              src={`${data.topComponentVideo}`}
              title="YouTube video player"
              allow="autoplay; clipboard-write; web-share"
              frameBorder={0}
              allowFullScreen
            />
          </Modal>
        </>}
    </div>

  );
}

export default CMediaTextCard;
