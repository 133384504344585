import { Box, Button, Modal } from "@mui/material";
import { absoluteCenter } from "src/utils/window";

interface IProps {
  text: string;
  descText?: string;
  buttonText: string;
  onClose: () => void;
}

export function CSuccessPopup({ text,descText, buttonText, onClose }: IProps) {
  return (
    <Modal
      open={true}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={absoluteCenter}>
        <div className="w-[360px] lg:w-[380px] p-[24px] bg-white rounded-[8px] flex-col justify-center items-center">
          <div className="flex-col items-center">
            <img src="/images/forgot_pass_success.svg" />
            <div className="pt-[26px] text-black text-18-24-600 text-center">
              {text}
            </div>
            {descText &&
            <div className="pt-[26px] text-black text-14-20-500 text-center">
              {descText}
            </div>
            }

            <div className="w-full flex pt-[24px] justify-center">
              <Button
                className="purpleButtonMini"
                onClick={() => {
                  onClose();
                }}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
