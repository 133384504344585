import {FilterModal} from "../Filter/FilterModal";
import 'dayjs/locale/tr';
import dayjs from "dayjs";
import {useContext, useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useDropzone} from "react-dropzone";
import {DateIcon} from "../../utils/mui";
import {MainContext} from "../../context/MainContext";
import {postV2} from "../../utils/request";
import {FilterConfig} from "../../types/TFilter";
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {byteArrayToBase64, getFile} from "../../utils/file";
import {TMediaObject} from "../../types/TMediaObject";
import {deleteMedias} from "../../service/profileRequest";
import {TError} from "../../types/TError";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  success: () => void
  skills: FilterConfig[]
  editData?: any
}

export function CPortfolioAddModal({getTranslation, isOpen, close, success, skills, editData}: IProps) {
  const mainContext = useContext(MainContext)
  const [startDateRef, setStartDateRef] = useState<any | undefined>(undefined)
  const [endDateRef, setEndDateRef] = useState<any | undefined>(undefined)
  const [startDate, setStartDate] = useState<dayjs.Dayjs | undefined>(undefined)
  const [endDate, setEndDate] = useState<dayjs.Dayjs | undefined>(undefined)
  const [name, setName] = useState('')
  const [details, setDetails] = useState('')
  const [link, setLink] = useState('')
  const [afiles, setAFiles] = useState<File[]>([])
  const [options, setOptions] = useState<FilterConfig[] | undefined>(undefined)
  const [tempVal, setTempVal] = useState<FilterConfig[] | undefined>(undefined)
  const [requesting, setRequesting] = useState(false)
  const [error, setError] = useState<any>()
  const [deletedImageKeys, setDeletedImageKeys] = useState<string[]>([])

  useEffect(() => {
    setOptions(skills)
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setDeletedImageKeys([])
      if (editData) {
        setName(editData.name)
        setDetails(editData.remark)
        setLink(editData.videoLink ?? '')
        setStartDate(dayjs(editData.startDateObj))
        setEndDate(dayjs(editData.endDateObj))
        const selectedSkills = options?.filter(o => editData.skills?.includes(o.id)) ?? []
        setTempVal(selectedSkills)
      } else {
        resetFields()
      }
    } else {
      resetFields()
    }
  }, [isOpen, editData]);

  function resetFields() {
    setStartDate(undefined)
    setEndDate(undefined)
    setName('')
    setDetails('')
    setLink('')
    setAFiles([])
    setTempVal(undefined)
  }


  const areFieldsOk = name.length > 2 && details.length > 2 && tempVal != undefined && tempVal.length > 0 && startDate != undefined && endDate != undefined && (link.length == 0 || ((): boolean => {
    let strings = link.split('.');
    if (strings.length < 2) {
      return false
    }
    const b = !(strings.filter(s => s.length < 3).length > 0);
    if (!b) {
      return b
    }
    let last = strings[strings.length - 1];
    if (last.includes("/")) {
      return last.split('/')[1].length > 1
    }
    if (last.includes("?")) {
      return last.split('?')[1].length > 1
    }
    return false
  })())

  const {acceptedFiles, getRootProps, getInputProps, inputRef} = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    }
  });

  useEffect(() => {
    setAFiles(acceptedFiles)
  }, [acceptedFiles]);
  const files = afiles.map(file => (
    <li key={file.name}>
      {file.name}
    </li>
  ));

  function save() {
    if (!areFieldsOk) {
      return false
    }
    setError(undefined)
    setRequesting(true)

    let data = {
      name: name,
      beginDate: startDate?.format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
      endDate: endDate?.format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
      remark: details,
      videoLink: link.length > 0 ? link : '',
      skills: tempVal?.map(o => o.id) ?? [],
      key: undefined
    };

    let url = "/ProfessionalPortfolio/create"
    if (editData) {
      url = "/ProfessionalPortfolio/update"
      data.key = editData.key
    }

    postV2(url, data).then(async (resp) => {
      setRequesting(false);
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        //request success
        const err = await deleteMedias('/ProfessionalPortfolioFile/deletefile', deletedImageKeys)
        if (err == undefined) {
          setError(undefined)
        } else {
          mainContext?.showToast(NotificationType.Fail, 'deleteFile')
        }
        if (acceptedFiles.length == 0) {
          //complete
          resetFields()
          mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
          success()
          close()
        } else {
          //save files first
          if (editData) {
            getAndSaveFile(editData.id)
          } else {
            getAndSaveFile(resp.data.id)
          }
        }
      }
    });
  }

  function saveFile(portfolioId: number, name: string, file: string) {
    let data = {
      professionalPortfolioID: portfolioId,
      fileName: name,
      fileContents: file
    };
    postV2("/ProfessionalPortfolioFile/createfile", data).then((resp) => {
      setRequesting(false);
      if (resp instanceof TError || resp.data.result == false) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        setError(undefined)
        resetFields()
        success()
        close()
      }
    });
  }

  function getAndSaveFile(portfolioId: number) {
    if (acceptedFiles.length == 0) {
      setError(undefined)
      resetFields()
      mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
      success()
      close()
      return
    }
    getFile(
      acceptedFiles[0],
      (filename, fileData) => {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        saveFile(portfolioId, filename, fileData)
      },
      (error) => {
        setRequesting(false);
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      }, mainContext
    )
  }

  let now = dayjs();
  useEffect(() => {
    if (startDate != undefined && endDate != undefined) {
      if (!endDate.isAfter(startDate)) {
        setEndDate(startDate)
      }
    }
  }, [startDate]);

  function Close() {
    if (requesting) {
      return
    }
    close();
  }

  return <FilterModal
    title={getTranslation('portfolyo')}
    header={''}
    isOpen={isOpen}
    onClose={Close}
    onSave={save}
    customHeaderClass={'!pt-[32px] !pb-[0px] max-h-[60vh]'}
    closeDisabled={requesting}
    saveDisabled={!areFieldsOk || requesting}
    error={error}
  >
    <div className={'overflow-y-auto max-h-[60vh]'}>
      <div className={'modalTitle pb-[16px]'}>{getTranslation('proje-ismi')}</div>
      <input placeholder={mainContext?.getTranslation('common', 'giriniz')}
             className={'standardInput !color-[#6D6D6D]'} value={name}
             onChange={(e) => {
               setName(e.target.value)
             }}
      />

      <div className={'modalTitle pt-[32px] pb-[16px]'}>{getTranslation('projeni-anlat')}</div>

      <textarea placeholder={getTranslation('projeni-anlat-detay')} rows={5}
                className={'standardInput py-[12px] !h-[180px] !color-[#6D6D6D]'}
                value={details} onChange={(e) => {
        setDetails(e.target.value)
      }}/>

      <div className={'modalTitle py-[16px]'}>{getTranslation('yetenekler')}</div>
      <CSelectWithSearchForFilter isMultiple={true} loading={options == undefined} options={options} val={tempVal}
        //@ts-ignore
                                  onChange={setTempVal}/>

      <div className={'pt-[36px] flex gap-[28px]'}>
        <div className={'flex-1 flex-col'}>
          <div className={'modalTitle pb-[16px]'}>{getTranslation('calisma-baslangici')}</div>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>
            <DatePicker className={'dateInput'} views={['year', 'month', 'day']}
                        value={startDate}
                        onAccept={(e) => setStartDate(e ?? undefined)}
                        components={{
                          OpenPickerIcon: DateIcon
                        }}
                        slotProps={
                          {
                            textField: {
                              onKeyDown: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              },
                              placeholder: 'Seçiniz', onClick: () => {
                                const founds = startDateRef?.getElementsByClassName('MuiIconButton-edgeEnd')
                                if (founds != undefined) {
                                  founds[0].click();
                                }
                              }
                            },
                          }
                        }
                        ref={(c) => {
                          setStartDateRef(c)
                        }}/>

          </LocalizationProvider>
        </div>
        <div className={'flex-1 flex-col'}>
          <div className={'modalTitle pb-[16px]'}>{getTranslation('calisma-bitisi')}</div>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>
            <DatePicker className={'dateInput'} views={['year', 'month', 'day']}
                        value={endDate}
                        onAccept={(e) => setEndDate(e ?? undefined)}
                        minDate={startDate == undefined ? undefined : startDate.add(1, 'day')}
                        components={{
                          OpenPickerIcon: DateIcon
                        }}
                        slotProps={{
                          textField: {
                            onKeyDown: (e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            },
                            placeholder: 'Seçiniz', onClick: () => {
                              const founds = endDateRef?.getElementsByClassName('MuiIconButton-edgeEnd')
                              if (founds != undefined) {
                                founds[0].click();
                              }
                            }
                          }
                        }}
                        ref={(c) => {
                          setEndDateRef(c)
                        }}/>

          </LocalizationProvider>
        </div>
      </div>
      {editData?.medias && editData.medias.length > deletedImageKeys.length &&
          <div className={'pt-[36px]'}>
              <div className={'modalTitle pb-[16px]'}>{getTranslation('yuklediklerini-sil')}</div>
              <div className={'w-full pr-[18px]'}>
                  <div className={'flex items-center gap-[16px] overflow-x-auto scrollMediaList'}>
                    {editData.medias.filter((m: TMediaObject) => !deletedImageKeys.includes(m.key))!.map((f: any, i: number) =>
                      <div className={'relative'}>
                        <div role={'button'} onClick={() => setDeletedImageKeys([...deletedImageKeys, f.key])}
                             className={'absolute top-[3px] right-[3px] w-[20] h-[20] bg-white rounded-[40%] flex items-center justify-center'}>
                          <img src={'/images/trashPurple.svg'}/>
                        </div>
                        <img role={'button'}
                             className={'rounded-[16px] max-w-[170px]'} src={f.url}
                             onClick={() => {
                               // setSelected(i)
                             }}/>
                      </div>
                    )}
                  </div>
              </div>
          </div>
      }
      <div className={'pt-[36px] flex gap-[28px]'}>
        <div className={'flex-1 flex-col'}>

          <div className={'modalTitle pb-[16px]'}>{getTranslation('projeni-buraya-yukle')}</div>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            {files.length > 0 ?
              <div className={'flex-col'}>
                <div className={'flex-1'}>
                  <ul><p>{files}</p></ul>
                </div>
                <div className={'flex justify-between'}>
                  <p role={'button'} onClick={(e) => {
                  }}><strong>{mainContext?.getTranslation('common', 'guncelle')}</strong></p>
                  <p role={'button'} onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setAFiles([])
                  }}><strong>{mainContext?.getTranslation('common', 'kaldir')}</strong></p>
                </div>
              </div>
              :
              <p>
                <strong>{mainContext?.getTranslation('common', 'dosya-sec')}</strong>&nbsp;{mainContext?.getTranslation('common', 'yada-dosya-surukle')}
              </p>
            }
          </div>

        </div>
        <div className={'flex-1 flex-col'}>
          <div className={'modalTitle pb-[16px]'}>&nbsp;</div>
          <div className={'flex-col justify-center items-center h-full w-full'}>
            <div className={'text-11-16-600 text-grey'}>
              {mainContext?.getTranslation('common', 'yada-video-link-ekle')}
            </div>
            <div className={'pt-[14px] w-full'}>
              <input placeholder={mainContext?.getTranslation('common', 'giriniz')}
                     className={'standardInput !color-[#6D6D6D]'} value={link}
                     onChange={(e) => {
                       setLink(e.target.value)
                     }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </FilterModal>
}
