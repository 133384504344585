import { TMenu } from "../types/TMenu";

function OTopMenu(
  getTranslation: (parent: string, child: string) => string
): TMenu[] {
  return [
    {
      title: <>{getTranslation("menu", "hakkimizda")}</>,
      link: "/hakkimizda",
      items: [
        { text: getTranslation("menu", "pofft-nedir"), link: "/hakkimizda" },
        { text: getTranslation("menu", "neden-pofft"), link: "/hakkimizda/#neden-pofft" },
        { text: getTranslation("menu", "pofft-nasil-calisir"), link: "/hakkimizda/#pofft-nasil-calisir" },
      ],
    },
    {
      title: <>{getTranslation("menu", "uzman")}</>,
      link: "/uzman",
      items: [
        { text: getTranslation('menu','pofft-uzmani-ol'), link: "/uzman" },
        { text: getTranslation('menu','uzmanlar-arasina-katil'), link: "/uzman/#katil" },
      ],
    },
    {
      title: <>{getTranslation("menu", "sirket")}</>,
      link: "/sirket",
      items: [
        {
          text: getTranslation("menu", "pofft-sirketi-ol"),
          link: "/sirket",
        },
        { text: getTranslation("menu", "sirketler-arasina-katil"), link: "/sirket/#katil" },
      ],
    },
    {
      title: <>{getTranslation("menu", "partner")}</>,
      link: "/partner",
      items: [
        { text: getTranslation('menu','pofft-ekosistemi-seni-bekliyor'), link: "/partner" },
        { text: getTranslation('menu','bize-yazin'), link: "/partner/#bizeyazin" },
      ],
    },
    // {
    //   title: <>Peerbie</>,
    //   link: "/peerbie",
    //   items: [
    //     { text: getTranslation("menu", "peerbie-nedir"), link: "/peerbie" },
    //     { text: getTranslation("menu", "neden-peerbie"), link: "/peerbie/#neden-peerbie" },
    //     { text: getTranslation("menu", "projelerini-nasil-daha-verimli-yonetirsin"), link: "/peerbie/#projelerini-nasil-daha-verimli-yonetirsin" },
    //   ],
    // }
  ];
}

export function OBottomMenu(
  getTranslation: (parent: string, child: string) => string
): TMenu[] {
  return [
    {
      title: <>{getTranslation("menu", "hakkimizda")}</>,
      link: "/hakkimizda",
      items: [
        { text: getTranslation("menu", "pofft-nedir"), link: "/hakkimizda/#pofft-nedir" },
        { text: getTranslation("menu", "neden-pofft"), link: "/hakkimizda/#neden-pofft" },
        { text: getTranslation("menu", "pofft-nasil-calisir"), link: "/hakkimizda/#pofft-nasil-calisir" },
      ],
    },
    {
      title: <>{getTranslation("menu", "sirketler")}</>,
      link: "/sirket",
      items: [
        {
          text: getTranslation("menu", "pofft-sirketi-ol"),
          link: "/sirket",
        },
        { text: getTranslation("menu", "sirketler-arasina-katil"), link: "/sirket/#katil" },
      ],
    },
    {
      title: <>{getTranslation("menu", "uzman")}</>,
      link: "/uzman",
      items: [
        { text: getTranslation('menu','pofft-uzmani-ol'), link: "/uzman" },
        { text: getTranslation('menu','uzmanlar-arasina-katil'), link: "/uzman/#katil" },
      ],
    },
    {
      title: <>{getTranslation("menu", "partner")}</>,
      link: "/partner",
      items: [
        { text: getTranslation('menu','pofft-ekosistemi-seni-bekliyor'), link: "/partner" },
        { text: getTranslation('menu','bize-yazin'), link: "/partner/#bizeyazin" },
      ],
    },
    {
      title: <>Blog</>,
      link: "/blogs",
      items: [
        // { text: getTranslation("menu", "peerbie-nedir"), link: "/peerbie" },
        // { text: getTranslation("menu", "neden-peerbie"), link: "/peerbie/#neden-peerbie" },
        // { text: getTranslation("menu", "projelerini-nasil-daha-verimli-yonetirsin"), link: "/peerbie/#projelerini-nasil-daha-verimli-yonetirsin" },
      ],
    }
  ];
}

export default OTopMenu;
