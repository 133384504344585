import {FilterModal} from "../Filter/FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {postV2} from "../../utils/request";
import {EnumProfileUpdateType} from "../../types/EnumProfileUpdateType";
import {TError} from "../../types/TError";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  update: (selectedTitle: string, newAbout: string) => void
  about: string
  title: string
}

export function CAboutEditModal({getTranslation, isOpen, close, update, title, about}: IProps) {
  const mainContext = useContext(MainContext)

  const [tempTitle, setTempTitle] = useState(title)
  const [tempAbout, setTempAbout] = useState(about)
  const [isUpdating, setIsUpdating] = useState(false)

  function save() {
    if (mainContext?.isExpired('aboutedit')) {
      return
    }
    if (tempTitle.length < 3 || tempAbout.length < 3) {
      return
    }
    setIsUpdating(true)
    postV2('/Professional/partialupdate', {
      updateType: EnumProfileUpdateType.Remark,
      remarkTitle: tempTitle,
      remark: tempAbout,
    }).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        update(tempTitle, tempAbout)
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  return <FilterModal
    title={getTranslation('hakkimda')}
    header={getTranslation('aktif-unvanin')}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={isUpdating}
    saveDisabled={tempTitle.length < 3 || tempAbout.length < 3 || isUpdating}
    customHeaderClass={'!pb-[20px]'}
  >
    <div>
      <div className={'w-full'}>
        <input placeholder={mainContext?.getTranslation('common', 'giriniz')}
               className={'standardInput !color-[#6D6D6D]'}
               value={tempTitle}
               onChange={(e) => {
                 let newVal = e.target.value;
                 if (newVal.length < 100) {
                   setTempTitle(newVal)
                 }
               }}
        />
      </div>
      <div className={'pt-[32px]'}>
        <div className={'modalTitle pb-[16px]'}>{getTranslation('kisaca-kendini-tanit')}</div>
      </div>
      <textarea placeholder={getTranslation('kisaca-kendini-tanit-detay')} rows={5}
                value={tempAbout}
                onChange={(e) => {
                  let newVal = e.target.value;
                  if (newVal.length < 4000) {
                    setTempAbout(newVal)
                  }
                }}
                className={'standardInput py-[12px] !h-[180px] !color-[#6D6D6D]'}
      />
    </div>
  </FilterModal>
}
