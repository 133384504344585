import {getV2} from "./request";
import {parseTranslation, Translation} from "../types/Translation";
import {TError} from "../types/TError";
import {NotificationType, ShowToast} from "../components/Toast/Toast";
import {enqueueSnackbar} from "notistack";

let toastTranslations = new Map<string, string>();

export async function mapToastTranslations(translations?: Map<string, Translation>) {
  if (translations && translations.has('toast')) {
    let toastMessages = translations.get("toast");
    if (toastMessages) {
      toastTranslations = toastMessages?.sentences;
    }
  } else {
    await getV2("/page/getpage?position=common", true).then((resp) => {
      if(resp instanceof TError){
        ShowToast(enqueueSnackbar,NotificationType.Fail, 'getPartners')
        return
      }
      const translationMap = parseTranslation(resp.data);
      let toastMessages = translationMap.get("toast");
      if (toastMessages) {
        toastTranslations = toastMessages?.sentences;
      }
    })
  }
}

export function getToastError(Key:string){
  const key=`error_${Key}`
  if(toastTranslations.has(key)) {
    return toastTranslations.get(key);
  }
  if(toastTranslations.has('fail')) {
    return toastTranslations.get('fail');
  }
}
export function getToastSuccess(Key:string){
  const key=`success_${Key}`
  if(toastTranslations.has(key)) {
    return toastTranslations.get(key);
  }
  if(toastTranslations.has('success')) {
    return toastTranslations.get('success');
  }
}
