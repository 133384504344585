import {UserType} from "./TUserData";

export enum TMessageRoomRelationType {
  Project,
  PublishProduct
}

export enum TMessageRoomRelationTypeStr {
  Project = 'Project',
  PublishProduct = 'PublishProduct',
}

export enum MessageType {
  File = 'File',
  Message = 'Message',
  Revision = 'Revition'
}

export function getMessageTypeFromStr(s: string): MessageType {
  switch (s) {
    case MessageType.File:
      return MessageType.File
    case MessageType.Revision:
      return MessageType.Revision
    default:
      return MessageType.Message
  }
}

export function TMessageRoomRelationTypeFromStr(s: TMessageRoomRelationTypeStr) {
  switch (s) {
    case TMessageRoomRelationTypeStr.Project:
      return TMessageRoomRelationType.Project
    case TMessageRoomRelationTypeStr.PublishProduct:
      return TMessageRoomRelationType.PublishProduct
  }
}

export function TMessageRoomRelationTypeToStr(s: TMessageRoomRelationType) {
  switch (s) {
    case TMessageRoomRelationType.Project:
      return TMessageRoomRelationTypeStr.Project
    case TMessageRoomRelationType.PublishProduct:
      return TMessageRoomRelationTypeStr.PublishProduct
  }
}

export interface TMessageRoom {
  roomKeyId: string,
  transactionDateStr: string,
  transactionDateObj: Date,
  messages: TMessage[],
  users: TMessageUser[],
  relations: TMessageRoomRelation[],
}

export interface TMessage {
  keyId: string,
  userKeyId: string,
  message: string,
  messageType: MessageType,
  dateStr: string,
  dateObj: Date,
  reads: TMessageRead[],
  user?: TMessageUser,
  relationName?: string
}

export interface TMessageRead {
  userKeyId: string,
  dateStr: string,
  dateObj: Date
  user?: TMessageUser
}

export interface TMessageUser {
  type: UserType,
  keyId: string,
  roomKeyId: string,
  name: string,
  photo: string,
}

export interface TMessageRoomRelation {
  type: TMessageRoomRelationType,
  keyId: string,
  status: string,
  name: string
}


function findUserByKeyId(users: TMessageUser[], keyId: string): TMessageUser | undefined {
  let found = users.find(u => u.keyId === keyId);
  if (found == undefined) {
    found = users.find(u => u.roomKeyId === keyId)
  }
  return found
}

export function parseMessageRooms(data: any): TMessageRoom[] {
  return data.map((r: any, i: number) => {
    return parseMessageRoom(r)
  });
}

export function parseMessageRoom(room: any): TMessageRoom {
  const users: TMessageUser[] = room.userList.map((u: any) => {
    return {
      type: u.userRelationType,
      keyId: u.userRelationKey,
      roomKeyId: u.roomUserKey,
      name: u.userInfo,
      photo: u.photo
    }
  })
  const messages = parseMessages(room.roomMessageList, users)
  const relations = room.roomRelationList.map((r: any) => {
    return {
      type: TMessageRoomRelationTypeFromStr(r.relationType),
      keyId: r.relationKey,
      status: r.status,
      name: r.relationName
    }
  })
  return {
    roomKeyId: room.roomKey,
    transactionDateStr: room.transactionDate,
    transactionDateObj: new Date(room.transactionDate),
    messages: messages,
    users: users,
    relations: relations
  }
}

export function parseMessages(messagesData: any[], users: TMessageUser[]): TMessage[] {
  return messagesData.map((m: any) => {
    return parseMessage(m, users)
  })
}

//@ts-ignore
export function parseMessage(m: any, users: TMessageUser[]): TMessage {
  try {
    return {
      keyId: m.roomMessageKey ?? m.RoomMessageKey,
      userKeyId: m.roomUserKey ?? m.RoomUserKey,
      messageType: getMessageTypeFromStr(m.messageType ?? m.MessageType),
      message: m.message ?? m.Message,
      dateStr: m.transactionDate ?? m.TransactionDate,
      dateObj: new Date(m.transactionDate ?? m.TransactionDate),
      user: findUserByKeyId(users, m.roomUserKey ?? m.RoomUserKey),
      relationName: m.relationName ?? m.RelationName,
      reads: (m.roomMessageReadList ?? m.RoomMessageReadList).map((r: any) => {
        return {
          userKey: r.roomUserKey ?? r.RoomUserKey,
          dateStr: r.readDate ?? r.ReadDate,
          dateObj: new Date(r.readDate ?? r.ReadDate),
          user: findUserByKeyId(users, r.roomUserKey ?? r.RoomUserKey)
        }
      })
    }
  }catch (e) {
    debugger
    console.log('Error parsing message: ', m)
    console.log('Error: ', e)
  }
}
