import {useContext, useEffect, useState} from "react";
import {MainContext} from "../context/MainContext";
import {useTranslation} from "../hooks/TranslationHook";
import CLoadingAnimation from "../components/CLoadingAnimation";
import {CompanyProfileData} from "../types/TCompanyData";
import {getMyCompanyData} from "../service/companyRequest";
import {TError} from "../types/TError";
import {PageState} from "../types/Context";
import {
  currencies,
  getCities,
  getCountries,
  getCurrencies,
  getLanguages,
  getProfessions,
  getSkills,
  getWorkingPriceTypes,
  getWorkingTypes,
  languages,
  professions,
  skills,
  workingPriceTypes,
  workingTypes
} from "../service/commonRequest";
import {useNavigate} from "react-router-dom";
import {GetProjectDetail} from "../service/projectRequest";
import {TProject} from "../types/TProject";
import {FilterConfig} from "../types/TFilter";
import {CSelectWithSearchForFilterSingle} from "../components/Input/CSelectWithSearchForFilterSingle";
import {CSelectWithSearchForFilter} from "../components/Input/CSelectWithSearchForFilter";
import {priceInput_purple_border_more_round} from "../components/Numeric/PriceInput";
import {TextField} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {DateIcon} from "../utils/mui";
import dayjs, {Dayjs} from "dayjs";
import {ModalButton} from "../components/Input/ModalButton";
import {postV2} from "../utils/request";
import {CProjectUpsertModal} from "../components/CProjectUpsertModal";
import {NotificationType} from "../components/Toast/Toast";

export function ProjectUpsert() {
  const mainContext = useContext(MainContext);
  const getTranslation = useTranslation('proje-sayfa', 'sayfa');
  const getProfileTranslation = useTranslation('profil-sayfa', 'sayfa');

  const [profileData, setProfileData] = useState<CompanyProfileData | undefined>(undefined)
  const [project, setProject] = useState<TProject | undefined>(undefined)
  const [updateProject, setUpdateProject] = useState<TProject | undefined>(undefined)
  const isLoaded = profileData != undefined && (!window?.location.href.includes('#') || project != undefined)

  async function fetchCommonData() {
    await getProfessions(mainContext)
    await getLanguages(mainContext)
    await getWorkingTypes(mainContext)
    await getWorkingPriceTypes(mainContext)
    await getSkills(mainContext)
    await getCountries(mainContext)
    await getCities(mainContext)
    await getCurrencies(mainContext)
    fetchProfileData()
  }

  async function fetchProfileData() {
    setProfileData(undefined)
    await getMyCompanyData().then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'getData')
      } else {
        setProfileData(r)
      }
    })
  }

  async function fetchProjectData(projectKey: string) {
    GetProjectDetail(projectKey).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'getProject')
      } else {
        setProject(r)
      }
    })
  }

  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 150);
    if (mainContext?.userData?.token) {
      const isExpired = mainContext.isExpired('projectupsert')
      //berkExpire
      if (isExpired) {
        setTimeout(() => {
          mainContext.setSigninupState(PageState.Signin)
        }, 1500)
        return
      }
      fetchCommonData().then(() => {
        const refs = window.location.href.split("#");
        if (refs != null && refs.length == 2) {
          const projectKey = refs[1];
          fetchProjectData(projectKey)
        }
      })

    }
  }, [mainContext?.userData?.token]);

  const [tempTitle, setTempTitle] = useState('')
  const [tempDetail, setTempDetail] = useState('')
  const [tempProfessionsStr, setTempProfessionsStr] = useState('')
  const [tempProfessions, setTempProfessions] = useState<FilterConfig[]>([])
  const [tempSkills, setTempSkills] = useState<FilterConfig[]>([])
  const [tempSkillsStr, setTempSkillsStr] = useState('')
  const [tempLanguages, setTempLanguages] = useState<FilterConfig[]>([])
  const [tempWorkingTypes, setTempWorkingTypes] = useState<FilterConfig[]>([])
  const [tempCurrency, setTempCurrency] = useState<undefined | FilterConfig>(undefined)
  const [tempWorkingPriceType, setTempWorkingPriceType] = useState<undefined | FilterConfig>(undefined)
  const [tempMinPrice, setTempMinPrice] = useState(0)
  const [tempMaxPrice, setTempMaxPrice] = useState(0)
  const [tempStartDate, setTempStartDate] = useState<Dayjs | null>(null)
  const [tempEndDate, setTempEndDate] = useState<any | null>(null)
  const [startDateRef, setStartDateRef] = useState<any | undefined>(undefined)
  const [endDateRef, setEndDateRef] = useState<any | undefined>(undefined)
  const [requesting, setRequesting] = useState(false)
  const [upserted, setUpserted] = useState<string | undefined>(undefined)


  useEffect(() => {
    if (project != undefined) {
      setTempTitle(project.title)
      setTempDetail(project.description)
      setTempProfessionsStr(project.professionManual ?? '')
      setTempSkillsStr(project.skillManual ?? '')
      setTempMinPrice(parseFloat(project.priceRange[0]))
      setTempMaxPrice(parseFloat(project.priceRange[1]))
      if (project.startDateObj) {
        setTempStartDate(dayjs(project.startDateObj))
      }
      if (project.endDateObj) {
        setTempEndDate(dayjs(project.endDateObj))
      }
      if (project.workingTypesMap) {
        let workinTypes = workingTypes.filter(w => project.workingTypesMap?.find(ww => ww.id == w.id));
        setTempWorkingTypes(workinTypes)
      }
      if (project.skillsMap) {
        let skill = skills.filter(s => project.skillsMap?.find(ss => ss.id == s.id));
        setTempSkills(skill)
      }
      if (project.workingPriceType) {
        let workingPriceType = workingPriceTypes.find(w => project.workingPriceType!.id);
        setTempWorkingPriceType(workingPriceType)
      }
      if (project.languagesMap) {
        let language = languages.filter(l => project.languagesMap?.find(ll => ll.id == l.id));
        setTempLanguages(language)
      }
      if (project.professionsMap) {
        let profession = professions.filter(p => project.professionsMap?.find(pp => pp.id == p.id));
        setTempProfessions(profession)
      }
      if (project.currency) {
        let currency = currencies.find(w => project.currency!.id);
        setTempCurrency(currency)
      }
    }
  }, [project]);

  const isSaveDisabled = tempTitle.length < 3 || tempDetail.length < 3 || tempCurrency == undefined ||
    tempMinPrice <= 0 || tempMaxPrice <= 0 || tempMinPrice > tempMaxPrice ||
    tempStartDate == null || tempEndDate == null ||
    tempWorkingTypes.length == 0 || tempWorkingPriceType == undefined;

  const setMinPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newVal = parseFloat(event.target.value);
    setTempMinPrice(newVal);
    if (tempMaxPrice < newVal) {
      setTempMaxPrice(newVal)
    }
  };
  const setMaxPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempMaxPrice(parseFloat(event.target.value));
  };

  function fieldCard(title: string, component: JSX.Element, secondaryTitle?: string, secondaryTitleNote?: string, secondaryComponent?: JSX.Element,customClass?:string) {
    return <div className={`div-lightest-purple rounded-[16px] py-[28px] px-[24px] flex-col gap-[24px] ${customClass}`}>
      <p className={'text-18-24-700 text-black'}>{title}</p>
      {component}
      {(secondaryTitle || secondaryTitleNote) &&
          <div className={'flex-col gap-[8px]'}>
            {secondaryTitle && <p className={'text-18-24-700 text-purple'}>{secondaryTitle}</p>}
            {secondaryTitleNote && <p className={'text-12-16-400 text-grey'}>{secondaryTitleNote}</p>}
          </div>
      }
      {secondaryComponent}
    </div>
  }

  function miniFieldCard(title: string, component: JSX.Element) {
    return <div className={'flex-col gap-[16px] w-full'}>
      <p className={'text-16-24-700 text-black'}>{title}</p>
      {component}
    </div>
  }

  function cancel() {
    navigate(-1)
  }

  function save() {
    if (isSaveDisabled) {
      return
    }
    setRequesting(true)
    let data: any = {
      active: true,
      name: tempTitle,
      remark: tempDetail,
      workingPriceTypeID: tempWorkingPriceType?.id,
      priceRangeBegin: tempMinPrice,
      priceRangeEnd: tempMaxPrice,
      workingTypes: tempWorkingTypes.map(w => '' + w.id),
      skills: tempSkills ? tempSkills.map(s => '' + s.id) : [],
      professions: tempProfessions ? tempProfessions.map(p => '' + p.id) : [],
      languages: tempLanguages ? tempLanguages.map(l => '' + l.id) : [],
      cityID: profileData?.cityID,
      countryID: profileData?.countryID,
      currencyID: tempCurrency?.id,
      skillOffer: tempSkillsStr,
      professionOffer: tempProfessionsStr,
      beginDate: tempStartDate?.format('YYYY-MM-DD'),
      deadline: tempEndDate?.format('YYYY-MM-DD'),
      address: profileData?.address,
    }
    if (profileData?.contactPerson) {
      data.contactPersonName = profileData.contactPerson.name
      data.contactPersonSurname = profileData.contactPerson.surname
      data.contactPersonEmail = profileData.contactPerson.email
      data.contactPersonPhone = profileData.contactPerson.phone
    }
    if (project) {
      data.projectKeyID = project.projectKeyID
    }

    postV2(project ? '/Project/update' : '/Project/create', data).then(resp => {
      setRequesting(false);
      if (resp instanceof TError || resp.data == undefined || resp.data.result == false) {
        mainContext?.showToast(NotificationType.Fail, 'updateProject')
      } else if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, 'updateProject')
        if (project) {
          setUpserted(project.projectKeyID)
        } else {
          setUpserted(resp.data.key)
        }
      }
    });
  }

  return <div
    className=" main-padding m-auto pt-[96px] pb-[48px] lg:pb-[72px] items-center h-full flex-col gap-[30px]">
    {!isLoaded ? <div className={`h-[100vh]`}><CLoadingAnimation/></div> : <>
      <div
        className={`flex flex-col gap-[24px] w-full h-[188px] bg-gray-300 px-[30px] pt-[72px] rounded-[16px] overflow-hidden whiteShadowCard relative`}>
        <div className={'absolute top-0 left-0 bottom-0 right-0 flex'}>
          <img className={'w-full object-cover'} src={profileData?.pagePhoto?.url}/>
        </div>
        <p className={'z-10 text-white text-24-24-900'}>{profileData?.name}</p>
        <p className={'z-10 text-white text-24-24-500'}>{profileData?.bannerText}</p>
      </div>
      <div className={'w-[960px] flex-col gap-[30px]'}>
        <p
          className={'text-18-24-600 text-purple'}>{getTranslation(project ? 'proje-ilan-guncelle' : 'proje-ilan-olustur')}</p>
        {fieldCard(getTranslation('ilan-basligi'),
          <input placeholder={getTranslation('ilan-basligi-aciklama')}
                 className={'standardBigInput'}
                 value={tempTitle}
                 onChange={(e) => {
                   let newVal = e.target.value;
                   setTempTitle(newVal)
                 }}
          />)}
        {fieldCard(getTranslation('ilan-detayi'),
          <textarea placeholder={getTranslation('ilan-detayi-aciklama')}
                    className={'standardBigInput !h-[388px] y-scroll'}
                    value={tempDetail}
                    onChange={(e) => {
                      let newVal = e.target.value;
                      if (newVal.length > 5600) {
                        return
                      }
                      setTempDetail(newVal)
                    }}
          />)}
        {fieldCard(getTranslation('aradiginiz-uzmanliklari-seciniz'),
          <CSelectWithSearchForFilter customClass={'bigSelector'}
                                      placeholder={getTranslation('aradiginiz-uzmanliklari-seciniz-aciklama')}
                                      val={tempProfessions} options={professions} isMultiple={true} loading={!isLoaded}
            //@ts-ignore
                                      onChange={setTempProfessions}
          />, getTranslation('aradiginiz-uzmanlik-bulamadiniz-mi'), getTranslation('uzmanlik-virgulle-ayir'),
          <input placeholder={mainContext?.getTranslation('common', 'giriniz')}
                 className={'standardBigInput'}
                 value={tempProfessionsStr}
                 onChange={(e) => {
                   let newVal = e.target.value;
                   setTempProfessionsStr(newVal)
                 }}
          />)}
        {fieldCard(getTranslation('aradiginiz-yetenekleri-seciniz'),
          <CSelectWithSearchForFilter customClass={'bigSelector'}
                                      placeholder={getTranslation('aradiginiz-yetenekleri-seciniz-aciklama')}
                                      val={tempSkills} options={skills} isMultiple={true} loading={!isLoaded}
            //@ts-ignore
                                      onChange={setTempSkills}
          />, getTranslation('aradiginiz-yetenek-bulamadiniz-mi'), getTranslation('yetenek-virgulle-ayir'),
          <input placeholder={mainContext?.getTranslation('common', 'giriniz')}
                 className={'standardBigInput'}
                 value={tempSkillsStr}
                 onChange={(e) => {
                   let newVal = e.target.value;
                   setTempSkillsStr(newVal)
                 }}
          />)}
        {fieldCard(getTranslation('proje-dili-secin'),
          <CSelectWithSearchForFilter customClass={'bigSelector'} placeholder={getTranslation('proje-dili-aciklama')}
                                      val={tempLanguages} options={languages} isMultiple={true} loading={!isLoaded}
            //@ts-ignore
                                      onChange={setTempLanguages}
          />,undefined,undefined,undefined,'zFrontUlChild')}
        {fieldCard(getTranslation('ucret-secenegi-giriniz') + ' *',
          <div className={'flex gap-[24px] justify-between'}>
            {miniFieldCard(getTranslation('para-birimi'), <CSelectWithSearchForFilterSingle options={currencies}
                                                                                            customClass={'mediumSingleSelector'}
                                                                                            val={tempCurrency}
              //@ts-ignore
                                                                                            onChange={setTempCurrency}/>)}
            {miniFieldCard(getTranslation('en-dusuk-ucret'), <TextField className={'bg-white rounded-[16px]'}
                                                                        placeholder={mainContext?.getTranslation('common', 'giriniz')}
                                                                        value={tempMinPrice}
                                                                        onChange={setMinPrice}
                                                                        disabled={tempCurrency == undefined}
                                                                        InputProps={{
                                                                          inputComponent: priceInput_purple_border_more_round,
                                                                          inputProps: {customSuffix: tempCurrency?.code ?? ''}
                                                                        }}
                                                                        variant="outlined"
            />)}
            {miniFieldCard(getTranslation('en-yuksek-ucret'), <TextField className={'bg-white rounded-[16px]'}
                                                                         placeholder={mainContext?.getTranslation('common', 'giriniz')}
                                                                         value={tempMaxPrice}
                                                                         onChange={setMaxPrice}
                                                                         disabled={tempCurrency == undefined}
                                                                         InputProps={{
                                                                           inputComponent: priceInput_purple_border_more_round,
                                                                           inputProps: {customSuffix: tempCurrency?.code ?? ''}
                                                                         }}
                                                                         variant="outlined"
            />)}
          </div>)}
        {fieldCard(getTranslation('ucret-sekli-seciniz') + ' *',
          <CSelectWithSearchForFilterSingle options={workingPriceTypes}
                                            customClass={'bigSingleSelector'}
                                            val={tempWorkingPriceType}
            //@ts-ignore
                                            onChange={setTempWorkingPriceType}/>)}
        {fieldCard(getTranslation('calisma-sekli-seciniz') + ' *',
          <CSelectWithSearchForFilter customClass={'bigSelector'} placeholder={getTranslation('calisma-sekli-aciklama')}
                                      val={tempWorkingTypes} options={workingTypes} isMultiple={true}
                                      loading={!isLoaded}
            //@ts-ignore
                                      onChange={setTempWorkingTypes}
          />)}
        {fieldCard('',
          <div className={'w-full flex gap-[24px]'}>
            <div className={'flex-col flex-1 gap-[19px]'}>
              <p className={'text-18-24-700 text-black'}>{getTranslation('proje-baslangic-tarihi')} *</p>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>
                <DatePicker className={'dateInput bigDate'} views={['year', 'month', 'day']}
                            value={tempStartDate}
                            onAccept={(e) => setTempStartDate(e)}
                            disablePast={true}
                            components={{
                              OpenPickerIcon: DateIcon
                            }}
                            slotProps={
                              {
                                textField: {
                                  onKeyDown: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  },
                                  placeholder: mainContext?.getTranslation('common', 'seciniz'), onClick: () => {
                                    const founds = startDateRef?.getElementsByClassName('MuiIconButton-edgeEnd')
                                    if (founds != undefined) {
                                      founds[0].click();
                                    }
                                  }
                                },
                              }
                            }
                            ref={(c) => {
                              setStartDateRef(c)
                            }}/>

              </LocalizationProvider>
            </div>
            <div className={'flex-col flex-1 gap-[19px]'}>
              <p className={'text-18-24-700 text-black'}>{getTranslation('proje-bitis-tarihi')} *</p>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>
                <DatePicker className={'dateInput bigDate'} views={['year', 'month', 'day']}
                            value={tempEndDate}
                            disablePast={true}
                            shouldDisableDate={(day) => {
                              if (tempStartDate == undefined) {
                                return false;
                              } else {
                                return !dayjs(day).isAfter(tempStartDate)
                              }
                            }}
                            onAccept={(e) => setTempEndDate(e)}
                            components={{
                              OpenPickerIcon: DateIcon
                            }}
                            slotProps={
                              {
                                textField: {
                                  onKeyDown: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  },
                                  placeholder: mainContext?.getTranslation('common', 'seciniz'), onClick: () => {
                                    const founds = endDateRef?.getElementsByClassName('MuiIconButton-edgeEnd')
                                    if (founds != undefined) {
                                      founds[0].click();
                                    }
                                  }
                                },
                              }
                            }
                            ref={(c) => {
                              setEndDateRef(c)
                            }}/>
              </LocalizationProvider>
            </div>
          </div>
        )}
        <div className={"pt-[50px] flex justify-end gap-[16px]"}>
          <ModalButton className={'!py-[16px] !px-[48px] bg-white border-light-red'}
                       textClassName={'text-16-16-600 text-light-red'} loading={false} disabled={requesting}
                       text={mainContext?.getTranslation("common", "iptal") ?? ''} onClick={cancel}/>
          <ModalButton className={'!py-[16px] !px-[48px] div-green'} textClassName={'text-16-16-600 text-white'}
                       loading={requesting} disabled={requesting || isSaveDisabled}
                       text={mainContext?.getTranslation("common", "kaydet") ?? ''} onClick={save}/>
        </div>
      </div>
      <CProjectUpsertModal
        close={() => {
          navigate('/company-project/' + upserted)
        }}
        onClick={() => {
          navigate('/company-project/' + upserted)
        }}
        isOpen={upserted != undefined}
        getTranslation={getTranslation}
        textKey={project ? 'ilaniniz-guncellendi' : 'ilaniniz-olusturuldu'}
        descriptionTextKey={'sirket-ilan-yayinda-aciklama'}/>
    </>
    }
  </div>
}
