import { MainContext } from "src/context/MainContext";
import { CProfileCard } from "./Profile/CProfileCard";
import { useContext } from "react";

export function CShopProfile() {
  const mainContext = useContext(MainContext);

  return (
    <div
      id={"dashboardprofile"}
      className="flex-col min-w-[240px] w-[240px] pt-[24px]"
    >
      <div className="text-30-40-500 pb-[24px]">
        pofft Dükkan'a Hoş Geldin 👋
      </div>
      <div>
        <CProfileCard />
        <div
          className="rounded-[16px] div-mindaro px-[24px] py-[36px] mt-[24px]"
          style={{ boxShadow: "0px 20px 80px 0px #EBECF0" }}
        >
          <div className="flex justify-between items-center">
            <div className="text-16-24-500 text-black text-center">
              pofft Dükkan'da Uzmansan hem proje açabilir hem de yeteneğini
              gösterecek projelere katılabilirsin.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
