import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./App.scss";
import CFooter from "./components/CFooter";
import CTopBar from "./components/CTopBar";
import AboutUs from "./pages/AboutUs";
import Company from "./pages/Company";
import Expert from "./pages/Expert";
import Home from "./pages/Home";
import Partner from "./pages/Partner";
import Peerbie from "./pages/Peerbie";
import {MainContextProvider} from "./context/MainContext";
import {Dashboard} from "./pages/Dashboard";
import {LoginSuccess} from "./pages/LoginSuccess";
import {ResetPassword} from "./pages/ResetPassword";
import Blogs from './pages/Blogs';
import {Blog} from "./pages/Blog";
import {NotFound} from "./pages/NotFound";
import {CompanyJobAgreement} from "./pages/CompanyJobAgreement";
import {ProjectDetail} from "./pages/ProjectDetail/ProjectDetail";
import {Profile} from "./pages/Profile/Profile";
import {Shop} from "./pages/Shop";
import {ProviderServices} from "./pages/ProviderServices";
import {RecipientServices} from "./pages/RecipientServices";
import {Applications} from "./pages/Job/Applications";
import {IncomingOffers} from "./pages/Job/IncomingOffers";
import {CompanyProject} from "./pages/CompanyProject";
import {ProjectUpsert} from "./pages/ProjectUpsert";
import {Messages} from "./pages/Messages/Messages";
import {ToastProps, WebToast} from "./components/Toast/Toast";
import {SnackbarProvider} from "notistack";
import BRoot from "./components/BRoot";
import {Payments} from "./pages/Payments/Payments";
import PostPayment from "./pages/PostPayment/PostPayment";
import {ComingSoon} from "./pages/ComingSoon";
import React from "react";
import {RegisterSuccess} from "./pages/RegisterSuccess";

declare module 'notistack' {
  interface VariantOverrides {
    // removes the `warning` variant
    warning: false;
    // adds `myCustomVariant` variant
    myCustomVariant: true;
    webNotification: {
      Props: ToastProps;
    };
  }
}

function App() {
  return (
    <div className="App">
      <div className="root">
        <MainContextProvider>
          <BrowserRouter>
            <CTopBar/>
            <SnackbarProvider
              maxSnack={1}
              autoHideDuration={7000}
              Components={{
                webNotification: WebToast,
              }}
            >
              <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/hakkimizda" element={<AboutUs/>}/>
                <Route path="/uzman" element={<Expert/>}/>
                <Route path="/sirket" element={<Company/>}/>
                <Route path="/partner" element={<Partner/>}/>
                <Route path="/peerbie" element={<Peerbie/>}/>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/dashboard/liked" element={<Dashboard/>}/>
                <Route path="/applications" element={<Applications/>}/>
                <Route path="/incoming-offers" element={<IncomingOffers/>}/>
                <Route path="/shop" element={<Shop/>}/>
                <Route path="/comingsoon" element={<ComingSoon/>}/>
                <Route path="/shop/recipient" element={<RecipientServices/>}/>
                <Route path="/shop/provider" element={<ProviderServices/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/profile/settings" element={<Profile/>}/>
                <Route path="/profile/:type/:id" element={<Profile/>}/>
                <Route path="/profile/:type/:id/:id2" element={<Profile/>}/>
                <Route path="/profile/:type/:id/:id2/:id3" element={<Profile/>}/>
                <Route path="/profile/:type/:id/:id2/:id3/:id4" element={<Profile/>}/>
                <Route path="/blogs" element={<Blogs/>}/>
                <Route path="/blog" element={<Blog/>}/>
                <Route path="/project/:id" element={<ProjectDetail/>}/>
                <Route path="/messages" element={<Messages/>}/>
                <Route path="/payments" element={<Payments/>}/>
                <Route path="/payments/:id" element={<Payments/>}/>
                <Route path="/messages/:userId" element={<Messages/>}/>
                <Route path="/company-project/:id" element={<CompanyProject/>}/>
                <Route path="/company-project/:id/:id2" element={<CompanyProject/>}/>
                <Route path="/company-project/:id/:id2/:id3" element={<CompanyProject/>}/>
                <Route path="/company-project/:id/:id2/:id3/:id4" element={<CompanyProject/>}/>
                <Route path="/company-project/:id/:id2/:id3/:id4/:id5" element={<CompanyProject/>}/>
                <Route path="/project-upsert" element={<ProjectUpsert/>}/>
                <Route path="/companyjobagreement" element={<CompanyJobAgreement/>}/>
                <Route path='/post-payment' element={<PostPayment/>}/>
                <Route path="/Login/Success" element={<LoginSuccess/>}/>
                <Route path="/Login/ResetPassword" element={<ResetPassword/>}/>
                <Route path="/tr/Company/RegisterActivation" element={<RegisterSuccess/>}/>
                <Route path="/tr/Professional/RegisterActivation" element={<RegisterSuccess/>}/>
                <Route path='*' element={<NotFound/>}/>
              </Routes>
            </SnackbarProvider>
            <CFooter/>
          </BrowserRouter>
        </MainContextProvider>
      </div>
    </div>
  );
}

export default App;
