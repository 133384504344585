export class RPartners {
  pagePictureModelList: PartnerData[];

  constructor(pagePictureModelList: PartnerData[]) {
    this.pagePictureModelList = pagePictureModelList;
  }

  static parse(data: any): RPartners {
    const list = data.data[0].pagePictureModelList.map((item: any) => {
      return new PartnerData(item.pictureName);
    });
    return new RPartners(list);
  }
}

export class PartnerData {
  pictureName: string;

  constructor(pictureName: string) {
    this.pictureName = pictureName;
  }

  getUrl(): string {
    return "https://www.pofft.com/Upload/PagePicture/" + this.pictureName;
  }
} 