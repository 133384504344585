export function firstLetterUppercase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}


export function parsePhone(v: string): string {
  let val = v.replace(')', '').replace('(', '')
  if (val.startsWith('0')) {
    val = '+90' + val.substring(1)
  }
  return val
}


export function compareEqualityIn<V>(value: V, comparisons: V[]): boolean {
  return comparisons.find(c => c === value) != undefined
}

const normalPages = ['/hakkimizda', '/uzman', '/sirket', '/partner', '/blogs', '/blog']

export function isLoggedInPage() {
  const pathname = window.location.pathname
  let found = !compareEqualityIn(pathname, normalPages);
  debugger
  return found
}
