import {useContext, useEffect, useState} from "react";
import {checkPaymentStatus, PaymentStatus, TPaymentRequest} from "../../types/TPaymentRequest";
import {NotificationType} from "../../components/Toast/Toast";
import {MainContext} from "../../context/MainContext";
import {useNavigate} from "react-router-dom";
import {Translation} from "../../types/Translation";
import {getTranslations} from "../../service/commonRequest";
import {ModalButton} from "../../components/Input/ModalButton";
import CLoadingAnimation from "../../components/CLoadingAnimation";

var statusInterval: any = undefined;

export default function PostPayment() {
  const navigate = useNavigate()

  const mainContext = useContext(MainContext)
  const [paymentChecker, setPaymentChecker] = useState(true)
  const [paymentRequest, setPaymentRequest] = useState<undefined | TPaymentRequest>(undefined)
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  useEffect(() => {
    if (mainContext?.userData?.token && mainContext?.publicToken) {
      if (paymentChecker) {
        fetchData()
        let paymentStr = localStorage.getItem('payment');
        if (paymentStr == null) {
          mainContext?.showToast(NotificationType.Fail, 'paymentNotFound')
          navigate('/dashboard')
        } else if (paymentRequest == undefined) {
          setPaymentRequest(JSON.parse(paymentStr))
        }
      }
    }
  }, [paymentChecker, mainContext?.userData?.token, mainContext?.publicToken]);

  useEffect(() => {
    if (paymentRequest) {
      statusInterval = setInterval(() => {
        checkPayment()
      }, 10000)
    }
  }, [paymentRequest]);

  function checkPayment() {
    if (mainContext && paymentRequest) {
      checkPaymentStatus(paymentRequest!.token).then(r => {
        debugger
        if (r == PaymentStatus.Error) {
          mainContext?.showToast(NotificationType.Fail, 'payment')
          if (statusInterval) {
            clearInterval(statusInterval)
          }
          navigate('/payments')
        } else if (r == PaymentStatus.Success) {
          if (statusInterval) {
            clearInterval(statusInterval)
          }
          setPaymentChecker(false)
        }
      })
    }
  }

  function getPageTranslation(child: string): string {
    return getTranslation('sayfa', child) ?? "";
  }

  function getTranslation(parent: string, child: string): string {
    return translations?.get(parent)?.sentences.get(child) ?? "";
  }

  async function fetchData() {
    getTranslations('odeme-sayfa', (translations, data) => {
      setTranslations(translations);
    })
  }

  return <div className={'w-[100vw] h-[100vh]'}>
    <div className={'flex flex-col items-center justify-center h-full'}>
      <div className={'flex flex-col items-center gap-[16px]'}>
        {paymentChecker ? <>
          <CLoadingAnimation/>
          <div className={'text-24-24-500'}>{getPageTranslation('odeme-kontrol-ediliyor')}</div>
        </> : <>
          <img src={'/images/forgot_pass_success.svg'}/>
          <div className={'text-24-24-500 text-green'}>{getPageTranslation('odeme-basarili')}</div>
          <div className={'pt-[24px]'}>
            <ModalButton loading={false} disabled={false} text={getPageTranslation('odemeler')} onClick={() => {
              navigate('/payments')
            }}/>
          </div>
        </>}
      </div>

    </div>
  </div>
}
