export function isDesktop() {
  const width = window.innerWidth;
  return width > 1023;
}

export const absoluteCenter = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: isDesktop() ? "unset" : "scroll",
  maxHeight: "90vh",
  borderRadius: "8px",
  "&::-webkit-scrollbar": { display: "none" },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
};
