import {useContext, useEffect, useState} from "react";
import {RJob} from "../types/RJobs";
import {getV2} from "../utils/request";
import CSwiper3Loop from "./CSwiper3Loop";
import CSwiperTextCard from "./CSwiperTextCard";
import CLoadingAnimation from "./CLoadingAnimation";
import {isDesktop} from "../utils/window";
import CSwiper1 from "./CSwiper1";
import {Button} from "@mui/material";
import {MainContext} from "../context/MainContext";
import {PageState} from "../types/Context";

interface IProps {
  tag: string;
}

function CJobsSwiperSpecific({tag}: IProps) {
  const mainContext = useContext(MainContext)

  const [jobs, setJobs] = useState<undefined | RJob[]>(undefined);

  useEffect(() => {
    if (mainContext?.publicToken) {
      getV2(`/project/randomproject?count=10&source=&tag=${tag}`, true).then((resp) => {
        setJobs(
          resp.data.map((item: any) => {
            return new RJob(item.name, item.location);
          })
        );
      });
    }
  }, [mainContext?.publicToken]);

  return (
    <>
      {jobs == undefined ? (
        <CLoadingAnimation/>
      ) : isDesktop() ? (
        <>
          <CSwiper3Loop
            data={{
              cards: jobs!.map((job, i) => {
                return (
                  <CSwiperTextCard key={job.name.toLowerCase() + i} onClick={() => {
                    // window.location.href = ('https://login.pofft.com/tr-TR/Login');
                    mainContext?.seeAllJobs()
                  }} header={job.name} sub={job.workingTypeStr}/>
                );
              }),
            }}
          />
          <Button className="purpleButton" onClick={() => {
            // window.location.href = ('https://login.pofft.com/tr-TR/Login');
            mainContext?.seeAllJobs()
          }}>
            <span>{mainContext?.getTranslation('common','tum-ilanlari-gor')}</span>
          </Button>
        </>
      ) : (
        <CSwiper1
          data={{
            cards: jobs!.map((job, i) => {
              return (
                <CSwiperTextCard key={job.name.toLowerCase() + i} onClick={() => {
                  // window.location.href = ('https://login.pofft.com/tr-TR/Login');
                  mainContext?.seeAllJobs()
                }} header={job.name} sub={job.workingTypeStr}/>
              );
            }),
          }}
        />
      )}
    </>
  );
}

export default CJobsSwiperSpecific;
