export class RBlog {
  id:number;
  name: string;
  transactionDate: string;
  extra1: string;
  by: string;
  pagePictureModelList: BlogPictureData;
  pageContentModelCs:any;

  constructor(
    id:number,
    name: string,
    transactionDate: string,
    extra1: string,
    by: string,
    pagePictureModelList: BlogPictureData,
    pageContentModelCs:any
  ) {
    this.id=id;
    this.name = name;
    this.transactionDate = transactionDate;
    this.extra1 = extra1;
    this.by = by;
    this.pagePictureModelList = pagePictureModelList;
    this.pageContentModelCs=pageContentModelCs;
  }

  static parse(data: any): RBlog[] {
    const list = data.data.map((item: any) => {
      return new RBlog(
        item.id,
        item.name,
        item.transactionDate,
        item.extra1,
        item.extra2==null ? "Admin" : item.extra2,
        new BlogPictureData(item.pagePictureModelList[0].pictureName),
        item.pageContentModelCs
      );
    });
    return list;
  }
}

export class BlogPictureData {
  pictureName: string;

  constructor(pictureName: string) {
    this.pictureName = pictureName;
  }

  getUrl(): string {
    return "https://www.pofft.com/Upload/PagePicture/" + this.pictureName;
  }
}
