export interface TUserData {
  id: string
  nameId: string
  relationId: string
  email: string
  name: string
  picture: string
  expireDate: Date
  address: string
  userType: UserType
  title: string
  token: string
  score: number
}

export enum UserType {
  Professional = 'Professional',
  Company = 'Company'
}

export function parseFrom(obj: any, token: string, tokenData: any): TUserData {
  let parsedToken = {
    id: tokenData.nameid,
    nameId: tokenData.nameid,
    relationId: obj.relationId,
    name: obj.relationName,
    email: obj.email,
    picture: obj.profileFoto,
    address: obj.relationAdress,
    // userType: obj.memberType == 0 ? UserType.Expert : UserType.Company,
    userType: obj.memberType,
    title: obj.relationTitle,
    expireDate: new Date(tokenData.exp * 1000),
    token: token,
    score: obj.profilScore
  };
  return parsedToken
}
