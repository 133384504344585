import {MainContext} from "src/context/MainContext";
import {useContext, useEffect, useState} from "react";
import {Translation} from "../types/Translation";
import {useNavigate} from "react-router-dom";
import {CShopProfile} from "src/components/CShopProfile";

export enum ShopType {
  None = "",
  Provider = "provider",
  Recipient = "recipient",
}

export function RecipientServices() {
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);
  const [selectedRoute, setSelectedRoute] = useState(ShopType.None);
  useEffect(() => {
    // if (localStorage.getItem("userData") == null) {
    //   navigate("/");
    // }
  }, []);

  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  return (
    <div className="justify-between main-width-desktop m-auto main-padding pt-[96px] pb-[48px] lg:pb-[72px] items-center h-full">
      <div className="flex w-full relative">
        <CShopProfile />
        <div className={"w-full relative"}>
          <div className={"flex-col w-full flex-1 h-full max-h-[100%]"}>
            <div className="p-[24px] pt-0 pr-0 dashboardHeader">
              <div className="pt-[24px]">
                <div
                  className="rounded-[16px] div-mindaro py-[16px] px-[24px]"
                  style={{ boxShadow: "0px 20px 80px 0px #EBECF0" }}
                >
                  <div className="flex justify-between items-center">
                    <div className="text-24-40-500 text-black">
                      {mainContext?.getTranslation("common", "hadi-basla")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {false && (
                <div className={" h-full w-full flex px-[24px] z-[9999]"}>
                  <div
                    className={
                      "h-full w-full flex flex-col justify-center items-center bg-[#EBECF0AA]"
                    }
                  >
                    <CLoadingAnimation size={120} />
                  </div>
                </div>
              )} */}
            <div className="w-full ml-[24px] shop-select baseContainer"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
