export const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const multipleNameRegex =
  /^[a-zA-ZıIİĞğàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçşčšžŞÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂]{2,}([ ]{1}([a-zA-ZıIİğĞàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçşčšžŞÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂]{2,}))+$/;
export const optionalNameRegex =
  /^[a-zA-ZıIİğĞàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçşčšžŞÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂]{2,}([ ]{0,1}([a-z]{0}|[a-zA-ZıIİğĞàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçşčšžŞÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂]{2,}))+$/;
export const companyNameRegex =
  /^[0-9a-zA-ZıIİğĞàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçşčšžŞÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂]{2,}([ ]{0,1}([a-z]{0}|[0-9a-zA-ZıIİğĞàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçşčšžŞÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂]{2,}))+$/;
export const phoneRegex =
  /^(?:\+90.?5|0090.?5|905|0?5)(?:[01345][0-9])\s?(?:[0-9]{3})\s?(?:[0-9]{2})\s?(?:[0-9]{2})$/;

// export const passRegex = /^(?=.*\d)(?=.*[.!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const passRegex = /^(?=.*\d)(?=.*[.!@#$%^&*]).{7,}$/;
// export const PassRegex=/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
