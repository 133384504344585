import {useContext, useEffect, useState} from "react";
import {MainContext} from "../context/MainContext";
import {FilterModal} from "./Filter/FilterModal";
import {CCheckbox} from "./CCheckbox";
import styles from './Profile/Profile.module.scss'
import {TEducation} from "../types/TEducation";
import {getV2, postV2} from "../utils/request";
import {TError} from "../types/TError";
import {NotificationType} from "./Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  edit: (t: TEducation) => void
  close: () => void
  onUpdate: () => void
  educations: TEducation[]
}

export function CEducationsList({getTranslation, isOpen, close, onUpdate,edit, educations}: IProps) {
  const mainContext = useContext(MainContext)

  const [requesting, setRequesting] = useState(false)
  const [tempEducations, setTempEducations] = useState<TEducation[]>([])

  useEffect(() => {
    if (isOpen === true) {
      setTempEducations([...educations.map(c => ({...c}))])
    }
  }, [isOpen]);

  function changeVisibility(i: number) {
    let newProjects = [...tempEducations].map((t, ii) => {
      if (ii == i) {
        t.visible = !t.visible
      }
      return t
    });
    setTempEducations(newProjects)
  }

  function changeDeletion(e: any, i: number) {
    e.preventDefault();
    e.stopPropagation();
    let newProjects = [...tempEducations].map((t, ii) => {
      if (ii == i) {
        t.deleted = t.deleted != undefined ? !t.deleted : true
      }
      return t
    });
    setTempEducations(newProjects)
  }

  async function updateEducationVisibility(p: TEducation) {
    return await postV2('/ProfessionalEducation/visible', {id: p.id, visible: p.visible}).then((resp) => {
      if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, p.visible?'makeVisible':'makeInvisible')
        return true
      }
      if(resp instanceof TError){
        mainContext?.showToast(NotificationType.Fail, p.visible?'makeVisible':'makeInvisible')
      }
      return false
    });
  }

  async function deleteEducation(p: TEducation) {
    return await getV2('/ProfessionalEducation/delete?key=' + p.id).then((resp) => {
      if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        return true
      }
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      }
      return false
    });
  }

  async function save() {
    const newProjects = [...tempEducations]
    setRequesting(true)
    let didFail = false
    let isFound = false
    for (let i = 0; i < tempEducations.length; i++) {
      let find = educations.find(p => p.id == tempEducations[i].id);
      if (find && newProjects[i].deleted === true) {
        isFound = true
        const resp = await deleteEducation(tempEducations[i])
        if (resp == false) {
          didFail = true
          newProjects[i].visible = find.visible;
        }
      }
    }
    setRequesting(false)
    if (isFound) {
      onUpdate()
    }
    if (didFail == true) {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    } else if (isFound) {
      mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
      close();
    }
    setTempEducations(newProjects)
  }

  function Edit(e:any,t:TEducation){
    e.preventDefault();
    e.stopPropagation();
    edit(t)
  }

  const currentYear = new Date().getFullYear() + "";
  return <FilterModal
    title={mainContext?.getTranslation('filters', 'job-education') ?? ''}
    header={''}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={requesting}
    saveDisabled={requesting}
    closeDisabled={requesting}
    customHeaderClass={'!pb-0'}
  >
    <div className={'w-full'}>
      <div className="flex-col gap-[36px] text-grey text-14-24-500 max-h-[60vh]">
        {getTranslation('gorunmesini-istedigin-egitim')}
        <div className={'flex-col gap-[24px] overflow-y-auto'}>
          {tempEducations.map((t, i) => {
            return <div
              className={`rounded-[16px] py-[26px] px-[12px] flex ${t.deleted ? 'bg-[#FFAAAA]' : t.visible ? 'bg-[#DEDEFF]' : 'bg-[#F6F6FF]'}`}>
              <div className={'flex-col gap-[12px] pl-[18px] w-full'}>
                <div className={`${styles.content} flex justify-between`}>
                  <div className={'flex-col text-start items-start'}>
                    <h1 className={'!items-start !text-start'}>{t.schoolName}</h1>
                    <h2 className={'!items-start !text-start text-14-24-500'}>{t.departmentName}</h2>
                    <h3
                      className={'!items-start !text-start text-12-24-400'}>{t.details[0]}</h3>
                  </div>
                  <div className={'flex gap-[16px] mr-[18px]'}>
                    <div role={'button'} className={'pt-[1px]'}
                         onClick={(e) => Edit(e, t)}>
                      <img className={'w-[22px] h-[22px]'} src={'/images/editButton.svg'}/>
                    </div>
                    <div className={'w-[24px] h-[24px] '} onClick={(e) => changeDeletion(e, i)}
                         role={'button'}>
                      <img src={tempEducations[i].deleted ? '/images/trashBlack.svg' : '/images/trashPurple.svg'}/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          })}
        </div>

      </div>
    </div>
  </FilterModal>
}
