import {FilterModal} from "../Filter/FilterModal";
import 'dayjs/locale/tr';
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {CCheckbox} from "../CCheckbox";
import {postV2} from "../../utils/request";
import {EnumProfileUpdateType} from "../../types/EnumProfileUpdateType";
import {TError} from "../../types/TError";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  isOpen: boolean
  sms: boolean
  phone: boolean
  email: boolean
  onUpdate: (sms: boolean, phone: boolean, email: boolean) => void
  onClose: () => void
}

export function CProfileReachPermissionModal({isOpen, sms, phone, email, onUpdate, onClose}: IProps) {
  const mainContext = useContext(MainContext);

  const [tempSms, setTempSms] = useState(sms)
  const [tempPhone, setTempPhone] = useState(phone)
  const [tempEmail, setTempEmail] = useState(email)
  const [isUpdating, setIsUpdating] = useState(false)

  function handleSave() {
    setIsUpdating(true)
    postV2('/Professional/partialupdate', {
      updateType: EnumProfileUpdateType.Permission,
      "smsPermission": tempSms,
      "callPermission": tempPhone,
      "emailPermission": tempEmail,
    }).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
        return false
      }
      mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
      onUpdate(tempSms, tempPhone, tempEmail)
      onClose()
      return true
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
      return false
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  useEffect(() => {
    if (isOpen) {
      setTempSms(sms)
      setTempPhone(phone)
      setTempEmail(email)
    }
  }, [isOpen]);

  return <FilterModal
    title={mainContext!.getTranslation('profile', 'ticari-ileti-onayi')}
    header={mainContext!.getTranslation('profile', 'ticari-ileti-onayi-detay')}
    saveDisabled={isUpdating} isOpen={isOpen} onClose={onClose} onSave={handleSave}>
    <div
      className={`flex justify-start text-left items-center self-start`}
      style={isUpdating ? {pointerEvents: "none"} : {}}
    >
      <CCheckbox
        selected={tempSms}
        onChange={setTempSms}
        textHtml={
          <div className="text-12-16-400 text-black">
            {mainContext?.getTranslation("common", "sms")}
          </div>
        }
      />
      <CCheckbox
        selected={tempPhone}
        onChange={setTempPhone}
        textHtml={
          <div className="text-12-16-400 text-black">
            {mainContext?.getTranslation("common", "telefon")}
          </div>
        }
      />
      <CCheckbox
        selected={tempEmail}
        onChange={setTempEmail}
        textHtml={
          <div className="text-12-16-400 text-black">
            {mainContext?.getTranslation("common", "eposta")}
          </div>
        }
      />
    </div>
  </FilterModal>
}
