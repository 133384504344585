import {useContext, useEffect, useState} from "react";
import CLoadingAnimation from "src/components/CLoadingAnimation";
import {BlogPictureData, RBlog} from "src/types/RBlogs";
import {getV2} from "src/utils/request";
import {isDesktop} from "src/utils/window";

import "../styles/blog.css";
import {useSearchParams} from "react-router-dom";
import {MainContext} from "../context/MainContext";
import {TError} from "../types/TError";
import {NotificationType} from "../components/Toast/Toast";

export function Blog() {
  const mainContext = useContext(MainContext)

  const IsDesktop = isDesktop();

  const [blog, setBlog] = useState<undefined | RBlog>(undefined);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (mainContext?.publicToken) {
      const blogUri = `/page/getpageid?pageID=${searchParams.get(
        "id"
      )}&position=blog&pageSize=1&pageNumber=1&sortColumnName=ID&sortType=desc`;
      getV2(blogUri, true).then((resp) => {
        if(resp instanceof TError){
          mainContext.showToast(NotificationType.Fail, 'getBlog')
          return
        }
        const blogsData = new RBlog(
          resp.data.id,
          resp.data.name,
          resp.data.transactionDate,
          resp.data.extra1,
          "Admin",
          new BlogPictureData(resp.data.pagePictureModelList[0].pictureName),
          resp.data.pageContentModelCs
        );
        setBlog(blogsData);
      });
    }
  }, [mainContext?.publicToken]);

  return blog == undefined ? (
    <CLoadingAnimation/>
  ) : (
    <div
      className="flex justify-between main-width m-auto main-padding pt-[96px] lg:pt-[136px] relative items-center h-full flex-col">
      <div className="flex-col gap-[32px]">
        <div className="flex flex-wrap justify-around gap-x-[40px] gap-y-[30px] pt-[48px]">
          <div className="flex-col blog-page-content">
            <div
              className={
                (IsDesktop ? "flex items-center" : "flex-col") + " gap-[24px]"
              }
            >
              <img
                className="h-full object-cover max-w-[300px]"
                src={blog.pagePictureModelList.getUrl()}
              />
              <div className="text-24-40-500_32-40-500">{blog.name}</div>
            </div>
            <div
              className="pt-[36px]"
              dangerouslySetInnerHTML={{
                __html: blog.pageContentModelCs.content,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
