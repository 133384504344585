import {getV2, publicToken} from "../utils/request";
import {TError} from "../types/TError";
import {FilterConfig} from "../types/TFilter";
import {parseJobsResponse, TJob} from "../types/TJob";
import {MainContextType} from "../context/MainContext";
import {NotificationType, ShowToast} from "../components/Toast/Toast";
import {DataProps} from "../types/DataProps";
import {parseTranslation, Translation} from "../types/Translation";
import {enqueueSnackbar} from "notistack";

export var universityDepartments: FilterConfig[] = [];
export var universities: FilterConfig[] = [];
export var highschools: FilterConfig[] = [];
export var countries: FilterConfig[] = [];
export var currencies: FilterConfig[] = [];
export var cities: FilterConfig[] = [];
export var professions: FilterConfig[] = [];
export var workingPriceTypes: FilterConfig[] = [];
export var workingTypes: FilterConfig[] = [];
export var languages: FilterConfig[] = [];
export var skills: FilterConfig[] = [];


export function getTranslations(page:string,callback:(translations:Map<string,Translation>,data:DataProps[],resp:any)=>void){
  getV2(`/page/getpage?position=${page}`, true).then((resp) => {
    if(resp instanceof TError){
      ShowToast(
        enqueueSnackbar,
        NotificationType.Fail,
        'getTranslations'
      );
      return
    }
    const respData = DataProps.parseAll(resp);
    const parsed = parseTranslation(resp.data);
    callback(parsed,respData,resp);
  });
}

export function getWorkingPriceTypes(context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/workingpricetype/list`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getWorkingPriceTypes')
      return
    }

    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    workingPriceTypes = map
    return map
  });
}

export function getProfessions(context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/profession/list`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getProfessions')
      return
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    professions = map
    return map
  });
}

export function getLanguages(context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/language/list`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getLanguages')
      return
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    languages = map
    return map
  });
}

export function getWorkingTypes(context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/workingtype/list`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getWorkingTypes')
      return
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    workingTypes = map
    return map
  });
}

export function getSkills(context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/skill/list`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getSkills')
      return
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    skills = map
    return map
  });
}

export function getUniversityDepartments(id: number,context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/universitydepartment/list?universityID=${id}`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getUniversityDepartments')
      return resp
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    let New: FilterConfig[] = []
    for (let i = 0; i < map.length; i++) {
      const found = New.find(n => n.label === map[i].label)
      if (found == undefined) {
        New.push(map[i])
      }
    }
    map = New
    universityDepartments = New
    return New
  });
}

export function getUniversities(context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/university/list`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getUniversities')
      return
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    let New: FilterConfig[] = []
    for (let i = 0; i < map.length; i++) {
      const found = New.find(n => n.label === map[i].label)
      if (found == undefined) {
        New.push(map[i])
      }
    }
    map = New
    universities = map
    return map
  });
}

export function getHighschools(context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/highSchool/list`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getHighschools')
      return
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    highschools = map
    return map
  });
}

export function getCountries(context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/country/list`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getCountries')
      return
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    countries = map
    return map
  });
}

export function getCities(context?: MainContextType | null, forCountry?: number): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/city/list${forCountry ? ('?countryID=' + forCountry) : ''}`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getCities')
      return
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name};
    });
    if (forCountry == undefined) {
      cities = map
    }
    return map
  });
}

export function getCurrencies(context?: MainContextType | null): Promise<TError | FilterConfig[]> {
  return getV2(`/Common/currency/list`).then((resp) => {
    if (resp instanceof TError) {
      context?.showToast(NotificationType.Fail, 'getCurrencies')
      return
    }
    let map = resp.data.map((item: any) => {
      return {id: item.id, label: item.name, symbol: item.symbol, code: item.code};
    });
    currencies = map
    return map
  });
}

export function getActiveProjectsById(userId: string): Promise<TError | TJob[]> {
  return getV2(`/ProjectOffer/listbyactive?professionalKeyID=${userId}`).then((resp) => {
    if (resp instanceof TError) {
      return resp
    } else {
      return parseJobsResponse(resp.data)
    }
  }).catch((ex) => {
    return new TError(0, ex.message);
  });
}

export function getCompletedProjectsById(userId: string): Promise<TError | TJob[]> {
  return getV2(`/ProjectOffer/listbycomplate?professionalKeyID=${userId}`).then((resp) => {
    if (resp instanceof TError) {
      return resp
    } else {
      return parseJobsResponse(resp.data)
      // let map = resp.data.map((item: any) => {
      //   let country = countries.find((c) => c.id == item.project.countryID)?.label;
      //   let city = cities.find((c) => c.id == item.project.cityID)?.label;
      //   return {
      //     title: item.project.name,
      //     firm: item.companyStr,
      //     workType: item.project.workingTypes?.[0] ?? '',
      //     startDate: '-',
      //     endDate: item.project.deadline ? dateToString(item.project.deadline) : '-',
      //     description: item.project.remark,
      //     location: (city ? (city + ', ') : '') + (country ?? ''),
      //     //TODO: fix when BE is finished
      //     // medias: item.medias ?? [],
      //     medias: ['/images/homeHero1.png', '/images/homeHero2.png', '/images/homeHero1.png', '/images/homeHero2.png'],
      //     visible: item.visible ?? false
      //   }
      // });
      // return map
    }
  }).catch((ex) => {
    return new TError(0, ex.message);
  });
}
