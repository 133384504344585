import {FilterModal} from "../Filter/FilterModal";
import 'dayjs/locale/tr';
import dayjs from "dayjs";
import {useContext, useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateIcon} from "../../utils/mui";
import {MainContext} from "../../context/MainContext";
import {FilterConfig} from "../../types/TFilter";
import {postV2} from "../../utils/request";
import {CSelectWithSearchForFilterSingle} from "../Input/CSelectWithSearchForFilterSingle";
import {monthStringFromBackend} from "../../utils/date";
import {TError} from "../../types/TError";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  updated: () => void
  options: FilterConfig[]
  editData?: any
}

export function CExperienceAddModal({getTranslation, isOpen, close,updated, options, editData}: IProps) {
  let now = dayjs();
  const mainContext = useContext(MainContext)
  const [startDateRef, setStartDateRef] = useState<any | undefined>(undefined)
  const [endDateRef, setEndDateRef] = useState<any | undefined>(undefined)
  const [startDate, setStartDate] = useState<dayjs.Dayjs | undefined>(undefined)
  const [endDate, setEndDate] = useState<dayjs.Dayjs | undefined>(undefined)
  const [companyName, setCompanyName] = useState('')
  const [details, setDetails] = useState('')
  const [tempVal, setTempVal] = useState<FilterConfig[] | FilterConfig | undefined>(undefined)
  const [requesting, setRequesting] = useState(false)
  const [error, setError] = useState()

  const areFieldsOk = companyName.length > 2 && startDate != undefined && endDate != undefined && details.length > 2 && tempVal != undefined

  function resetData() {
    setCompanyName('')
    setDetails('')
    setStartDate(undefined)
    setEndDate(undefined)
    setTempVal(undefined)
  }

  function save() {
    if (!areFieldsOk) {
      return
    }

    setRequesting(true);
    let data = {
      companyName: companyName,
      year: startDate?.year(),
      endYear: endDate?.year(),
      month: (startDate?.month() ?? 0) + 1,
      endMonth: (endDate?.month() ?? 0) + 1,
      remark: details,
      //@ts-ignore
      professionID: tempVal.id,
      visible: true,
      key:undefined
    };
    let url = '/ProfessionalExperience/create'
    if (editData) {
      url = '/ProfessionalExperience/update'
      data.visible = editData.visible
      data.key = editData.key
    }
    postV2(url, data).then((resp) => {
      setRequesting(false);
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        close();
        updated();
      }
    });
  }

  function Close() {
    if (requesting) {
      return
    }
    close();
  }


  useEffect(() => {
    if (startDate != undefined && endDate != undefined) {
      if (!endDate.isAfter(startDate)) {
        setEndDate(startDate)
      }
    }
  }, [startDate]);


  useEffect(() => {
    if (isOpen) {
      if (editData) {
        setCompanyName(editData.companyName)
        setDetails(editData.remark)
        //@ts-ignore
        setTempVal(options.find((o) => o.id == editData.professionID))
        setStartDate(dayjs(editData.year + '-'+monthStringFromBackend(editData.month)+'-01T00:00:00.000Z'))
        setEndDate(dayjs(editData.endYear + '-'+monthStringFromBackend(editData.endMonth)+'-01T00:00:00.000Z'))
      } else {
        resetData()
      }
    }
  }, [isOpen, editData]);

  return <FilterModal
    title={getTranslation('tecrubeler')}
    header={getTranslation('sirket-adi')}
    isOpen={isOpen}
    onClose={Close}
    onSave={save}
    customHeaderClass={'!pb-[20px]'}
    saveDisabled={!areFieldsOk || requesting}
    closeDisabled={requesting}
    error={error}
  >
    <div className={'overflow-y-auto'}>
      <div className={'w-full'}>
        <input onChange={(e) => {
          setCompanyName(e.target.value)
        }} value={companyName} placeholder={mainContext?.getTranslation('common', 'giriniz')}
               className={'standardInput !color-[#6D6D6D]'}
        />
        <div className={'modalTitle py-[16px]'}>{getTranslation('uzmanlik')}</div>
        <CSelectWithSearchForFilterSingle options={options} val={tempVal} loading={options == undefined}
          //@ts-ignore
                                          onChange={setTempVal}/>

        <div className={'pt-[36px] flex gap-[28px]'}>
          <div className={'flex-1 flex-col'}>
            <div className={'modalTitle pb-[16px]'}>{getTranslation('calisma-baslangici')}</div>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>
              <DatePicker className={'dateInput'} views={['year', 'month']}
                          value={startDate}
                          onAccept={(e) => {
                            setStartDate(e ?? undefined)
                          }}
                          components={{
                            OpenPickerIcon: DateIcon
                          }}
                          slotProps={
                            {
                              textField: {
                                onKeyDown: (e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                },
                                placeholder: 'Seçiniz', onClick: () => {
                                  const founds = startDateRef?.getElementsByClassName('MuiIconButton-edgeEnd')
                                  if (founds != undefined) {
                                    founds[0].click();
                                  }
                                }
                              },
                            }
                          }
                          ref={(c) => {
                            setStartDateRef(c)
                          }}/>

            </LocalizationProvider>
          </div>
          <div className={'flex-1 flex-col'}>
            <div className={'modalTitle pb-[16px]'}>{getTranslation('calisma-bitisi')}</div>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>
              <DatePicker className={'dateInput'} views={['year', 'month']}
                          value={endDate}
                          onAccept={(e) => {
                            setEndDate(e ?? undefined)
                          }}
                          minDate={startDate == undefined ? undefined : startDate.add(1, 'month')}
                          components={{
                            OpenPickerIcon: DateIcon
                          }}
                          slotProps={{
                            textField: {
                              onKeyDown: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              },
                              placeholder: 'Seçiniz', onClick: () => {
                                const founds = endDateRef?.getElementsByClassName('MuiIconButton-edgeEnd')
                                if (founds != undefined) {
                                  founds[0].click();
                                }
                              }
                            }
                          }}
                          ref={(c) => {
                            setEndDateRef(c)
                          }}/>
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div className={'modalTitle py-[16px]'}>{getTranslation('yaptigin-isten-bahset')}</div>
      <textarea placeholder={getTranslation('projeni-anlat-detay')} rows={5}
                className={'standardInput py-[12px] !h-[180px] !color-[#6D6D6D]'}
                value={details} onChange={(e) => {
        setDetails(e.target.value)
      }}
      />
    </div>
  </FilterModal>
}
