import styles from "../../pages/Profile/Profile.module.scss";
import {useEffect, useRef, useState} from "react";
import {register} from "swiper/element";
import {mergeCss} from "../../utils/cssMerge";
import {CPortfolioAddModal} from "./CPortfolioAddModal";
import {CExperienceAddModal} from "./CExperienceAddModal";

interface IProps {
  title: string,
  components: any[]
  getTranslation: (key: string) => string
  slidesPerView: any
  gap?: string
  isAddable?: boolean
  isEditable?: boolean
  customClass?: string
  openAddModal?: () => void
  openEditModal?: () => void
}

export function ProfileSwiperCard({
                                    title,
                                    components,
                                    getTranslation,
                                    slidesPerView,
                                    gap,
                                    isAddable,
                                    isEditable,
                                    customClass,
                                    openAddModal,
                                    openEditModal
                                  }: IProps) {

  const [swiper, setSwiper] = useState<any | undefined>(undefined);
  const [canGoPrev, setCanGoPrev] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const swiperRef = useRef(null);

  const swiperId = 'swiper' + title.toLowerCase().replaceAll(' ', '_')

  useEffect(() => {
    setCanGoNext(components.length >= slidesPerView)
  }, [components]);

  useEffect(() => {
    setTimeout(() => {
      register();
      const swiperEl = document.getElementById(swiperId);
      if (swiperEl) {
        //@ts-ignore
        setSwiper(swiperEl.swiper)
      }
    }, 500)
  }, []);

  useEffect(() => {
    if (swiper != undefined) {
      swiper.on('slideChange', () => {
        setCanGoNext(!swiper.isEnd)
        setCanGoPrev(!swiper.isBeginning)
      })
      swiper.on('paginationUpdate', () => {
        if (swiper.isEnd) {
          setCanGoNext(false);
        }
      })
    }
  }, [swiper]);

  function add() {
    openAddModal?.()
  }

  function edit() {
    openEditModal?.()
  }

  return <>
    <div className={'whiteShadowCard py-[36px] px-[24px]'}>
      <div className={'flex justify-between'}>
        <div className={'flex gap-[14px]'}>
          <p className={styles.header}>{getTranslation(title)}</p>
          <div className={'flex gap-[16px]'}>
            {isAddable &&
                <img className={'preventSelect'} role={"button"} onClick={add} src={'/images/addButton.svg'}/>
            }
            {isEditable &&
                <div role={'button'} onClick={edit}
                     className={mergeCss([styles.editButton, '!relative preventSelect'])}>
                    <img src={'/images/editButton.svg'}/>
                </div>
            }
          </div>
        </div>
        <div className={'flex gap-[8px]'}>
          <div className={'h-[24px] w-[24px] preventSelect'}>
            {canGoPrev &&
                <img role={'button'}
                     onClick={() => {
                       swiper?.slidePrev();
                     }} src={'/images/LeftPagination.svg'}/>
            }
          </div>
          <div className={'h-[24px] w-[24px] preventSelect'}>
            {canGoNext &&
                <img role={'button'}
                     onClick={() => {
                       swiper?.slideNext();
                     }} src={'/images/RightPagination.svg'}/>
            }
          </div>
        </div>
      </div>
      <div className={'h-[24px]'}></div>
      <div className={`flex w-full tagsSwiper ${customClass ?? ''}`}>
        <swiper-container
          direction={'horizontal'}
          autoWidth={true}
          ref={swiperRef}
          id={swiperId}
          class={components.length == 1 ? 'w-full' : ''}
          pagination={'true'}
          slides-per-view={slidesPerView}
          space-between={gap ?? "16"}
        >
          {components.map(c =>
            <swiper-slide>
              {c}
            </swiper-slide>
          )}
        </swiper-container>
      </div>
    </div>
  </>
}
