import {Checkbox, FormControlLabel} from "@mui/material";

interface IProps {
  selected: boolean;
  onChange: (val: boolean) => void;
  textHtml: JSX.Element;
  disabled?: boolean;
  customClass?: string;
}

export function CCheckbox({selected, onChange, textHtml, disabled, customClass}: IProps) {
  return <div
    className={`basicCheckbox self-start ${
      selected ? "selected" : ""
    } ${customClass ?? ""}`}
    style={(disabled != undefined && disabled == true) ? {pointerEvents: "none"} : {}}
  >
    <FormControlLabel
      control={
        <div onClick={() => onChange(!selected)} className={'pl-[12px]'}>
          <img className={'w-[25px] h-[24px]'}
               src={selected ? "/images/CheckboxTrue.svg" : "/images/CheckboxFalse.svg"}/>
        </div>
      }
      label={
        textHtml
      }
    />
  </div>
}
