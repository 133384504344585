import {useContext, useEffect, useState} from "react";
import CHeroAboutUs from "../components/CHeroAboutUs";
import CPurpleTextArrow from "../components/CPurpleTextArrow";
import CLoadingAnimation from "src/components/CLoadingAnimation";
import {filter, getV2} from "src/utils/request";
import {DataProps} from "src/types/DataProps";
import {parseTranslation, Translation} from "src/types/Translation";
import {MainContext} from "src/context/MainContext";
import {getTranslations} from "../service/commonRequest";

export default function AboutUs() {

  const mainContext = useContext(MainContext);

  const [data, setData] = useState<DataProps[] | undefined>(undefined);
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  function getTranslation(child: string): string {
    return translations?.get("sayfa")?.sentences.get(child) ?? "";
  }

  useEffect(() => {
    if (mainContext?.publicToken) {
      getTranslations('hakkimizda',(translations,data)=>{
        setData(data);
        setTranslations(translations);
      })
    }
  }, [mainContext?.publicToken]);

  useEffect(() => {
    if (data != undefined) {
      setTimeout(() => {
        const refs = window.location.href.split("#");
        if (refs !== null && refs.length > 1) {
          const targetto = refs[1];
          const target = document.getElementById(targetto);
          const scrollToY = target!.offsetTop - 50;
          console.log(`Y: ${targetto} px: ${scrollToY}`);
          window.scrollTo(0, scrollToY);
        } else {
          window.scrollTo(0, 0);
        }
      }, 250);
    }
  }, [data]);

  return data == undefined ? (
    <div className="h-[100vh] w-[100vw] flex-col justify-center">
      <CLoadingAnimation />
    </div>
  ) : (
    (() => {
      const pictures = filter(data!, "sayfa");

      return (
        <>
          <CHeroAboutUs getTranslation={getTranslation} data={pictures} />
          {/* pageRow pb-[48px] lg:pb-[72px] */}
          <a id="neden-pofft"></a>
          <div className="flex justify-between main-width m-auto main-padding pt-[48px] lg:pt-[136px] relative items-center h-full flex-col-reverse lg:flex-row">
            <div className="shadow-image">
              <img
                src={pictures?.pagePictureModelList?.[1]?.getUrl()}
                style={{
                  filter: "drop-shadow(0px 20px 80px rgba(80, 66, 17, 0.13))",
                }}
              />
            </div>
            <div className="flex-col justify-left lg:pl-[88px] flex-1 items-center lg:items-start">
              <div className="text-32-40-500 text-black">
                {getTranslation("why_pofft")}
              </div>
              <ul
                className="pt-[16px] pl-[16px] text-16-24-400 text-grey"
                style={{ listStyle: "disc" }}
              >
                <li>{getTranslation("why_pofft_1")}</li>
                <li>{getTranslation("why_pofft_2")}</li>
                <li>{getTranslation("why_pofft_3")}</li>
                <li>{getTranslation("why_pofft_4")}</li>
                <li>{getTranslation("why_pofft_5")}</li>
                <li>{getTranslation("why_pofft_6")}</li>
                <li>{getTranslation("why_pofft_7")}</li>
              </ul>
            </div>
          </div>
          <a id="pofft-nasil-calisir"></a>
          <div className="flex justify-between main-width m-auto main-padding pt-[136px] relative items-center h-full flex-col lg:flex-row">
            <div className="flex-col justify-left lg:pr-[88px] flex-1 w-full items-center lg:items-start">
              <div className="text-32-40-500 text-black">
                {getTranslation("how_pofft_works")}
              </div>
              <div className="pt-[16px] text-16-24-400 text-grey">
                {getTranslation("how_pofft_works_text")}
              </div>
              <div className="pt-[55px] pb-[12px] w-full">
                <CPurpleTextArrow
                  link={"/uzman"}
                  text={mainContext?.getTranslation("common", "uzman") ?? ""}
                />
              </div>
              <div className="py-[12px] w-full">
                <CPurpleTextArrow
                  link={"/sirket"}
                  text={mainContext?.getTranslation("common", "sirket") ?? ""}
                />
              </div>
            </div>
            <div className="shadow-image">
              <img
                src={pictures?.pagePictureModelList?.[2]?.getUrl()}
                style={{
                  filter: "drop-shadow(0px 20px 80px rgba(80, 66, 17, 0.13))",
                }}
              />
            </div>
          </div>
          <div className="pt-[48px] lg:pt-[168px]"></div>
        </>
      );
    })()
  );
}
