import { Box, Button, Modal } from "@mui/material";
import { absoluteCenter } from "src/utils/window";

export interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  infoText?: string;
  infoTitle?: string;
  buttonText?: string;
}

export function CAgreementTextPopup({
  isOpen,
  onClose,
  onAccept,
  infoText,
  infoTitle,
  buttonText,
}: IProps) {
  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={absoluteCenter}>
        <div className="w-[360px] lg:w-[690px] p-[24px] bg-white rounded-[8px] flex-col justify-center items-start">
          <div className="w-full flex justify-between pb-[24px]">
            <div className="text-18-24-600 text-black">{infoTitle}</div>
            <div
              onClick={() => {
                onClose();
              }}
            >
              <img src="/images/close.svg" />
            </div>
          </div>
          <div className="text-14-24-600 text-grey pb-[24px]">{infoText}</div>
          <Button
            className="purpleButton pb-[32px]"
            onClick={() => {
              onAccept();
            }}
          >
            {buttonText}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
