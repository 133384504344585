export function dateStrToString(encoded?: string) {
  if (!encoded) return '-'
  return dateToString(new Date(encoded));
}

export function dateToString(date?: Date) {
  if (!date) return '-'
  return date.toLocaleDateString('tr-TR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

export function yyyymmToString(yyyy: number, mm: number) {
  if (yyyy != 0) {
    return yyyymmToDate(yyyy, mm).toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
    })
  }
  return '-'
}

export function yyyymmToDate(yyyy: number, mm: number) {
  const MM = mm > 0 ? mm - 1 : 0
  return new Date(yyyy, MM, 1)
}

export function dateToMonthYearString(encoded: string) {
  return new Date(encoded).toLocaleDateString('tr-TR', {
    month: 'long',
    year: 'numeric',
  })
}

export function getDiffFromToday(date: Date) {
  const today = new Date()
//@ts-ignore
  const diffTime = Math.abs(today - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays
}

export function getDiffToToday(date: Date) {
  const today = new Date()
//@ts-ignore
  const diffTime = Math.abs(date - today);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays
}

export function getDiffDates(startDate: Date, endDate: Date) {
//@ts-ignore
  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays
}


export function addDaysToDate(date: Date, days: number) {
  date.setDate(date.getDate() + days);
  return date;
}

export function monthStringFromBackend(val: number): string {
  if (val == 0) return '01'
  if (val < 10) {
    return '0' + val
  }
  return "" + val
}
