import {useContext, useEffect, useState} from "react";
import CHelpToWorkRow from "src/components/CHelpToWorkRow";
import CLoadingAnimation from "src/components/CLoadingAnimation";
import CPageSection from "src/components/CPageSection";
import { TMediaTextCardsRow } from "src/types/TComponents";
import {filter, getV2} from "src/utils/request";
import CHeroPeerbie from "../components/CHeroPeerbie";
import { DataProps } from "../types/DataProps";
import { Translation, parseTranslation } from "src/types/Translation";
import {MainContext} from "../context/MainContext";
import {getTranslations} from "../service/commonRequest";

export function OPeerbieSection(getTranslation:(key:string)=>string, data?: DataProps): TMediaTextCardsRow {
  return {
    data: [
      {
        header: getTranslation('proje-verimi-card1') ?? "",
        topComponentVideo: getTranslation('proje-verimi-link1') ?? "",
        topComponentImage: data?.pagePictureModelList?.[2]?.getUrl() ?? "",
      },
      {
        header: getTranslation('proje-verimi-card2') ?? "",
        topComponentVideo: getTranslation('proje-verimi-link2') ?? "",
        topComponentImage: data?.pagePictureModelList?.[3]?.getUrl() ?? "",
      },
      {
        header: getTranslation('proje-verimi-card3') ?? "",
        topComponentVideo: getTranslation('proje-verimi-link3') ?? "",
        topComponentImage: data?.pagePictureModelList?.[4]?.getUrl() ?? "",
      },
      {
        header: getTranslation('proje-verimi-card4') ?? "",
        topComponentVideo: getTranslation('proje-verimi-link4') ?? "",
        topComponentImage: data?.pagePictureModelList?.[5]?.getUrl() ?? "",
      },
    ],
  };
}

export default function Peerbie() {

  const mainContext=useContext(MainContext)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  const [data, setData] = useState<DataProps[] | undefined>(undefined);
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  function getTranslation(child: string): string {
    return translations?.get('sayfa')?.sentences.get(child) ?? "";
  }

  useEffect(() => {
    if (mainContext?.publicToken) {
      getTranslations('uzman-sayfa',(translations,data)=>{
        setData(data);
        setTranslations(translations);
      })
    }
  }, [mainContext?.publicToken]);

  useEffect(() => {
    if (data != undefined) {
      setTimeout(() => {
        const refs = window.location.href.split("#");
        if (refs !== null && refs.length > 1) {
          const targetto = refs[1];
          const target = document.getElementById(targetto);
          const scrollToY = target!.offsetTop;
          console.log(`Y: ${targetto} px: ${scrollToY}`);
          window.scrollTo(0, scrollToY-100);
        } else {
          window.scrollTo(0, 0);
        }
      }, 250);
    }
  }, [data]);

  return data == undefined ? (
    <div className="h-[100vh] w-[100vw] flex-col justify-center">
      <CLoadingAnimation />
    </div>
  ) : (
    (() => {
      const pictures = filter(data!, "sayfa");

      return (
        <>
          <CHeroPeerbie getTranslation={getTranslation} data={pictures} />
          <a id='neden-peerbie'></a>
          <div className="flex justify-between main-width m-auto main-padding pt-[48px] lg:pt-[136px] pb-[48px] lg:pb-[72px] relative items-center h-full flex-col-reverse lg:flex-row">
            <div className="shadow-image">
              <img
                src={pictures?.pagePictureModelList?.[1]?.getUrl() ?? ""}
                style={{
                  filter: "drop-shadow(0px 20px 80px rgba(80, 66, 17, 0.13))",
                }}
              />
            </div>
            <div className="flex-col justify-center lg:justify-left lg:pl-[88px] flex-1 text-center lg:text-start">
              <div className="text-32-40-500 text-black">
                {getTranslation('neden-peerbie')}
              </div>
              <div className="pt-[16px] text-16-24-400 text-grey">
                {getTranslation('neden-peerbie-aciklama')}
              </div>
            </div>
          </div>
          <a id="projelerini-nasil-daha-verimli-yonetirsin"></a>
          <CPageSection
            data={{
              headerTextClass:'text-32-40-500',
              header: getTranslation('proje-verimi-title'),
              sub: getTranslation('proje-verimi-sub'),
              parentClass:'!pb-0',
              component: <CHelpToWorkRow data={OPeerbieSection(getTranslation,pictures)} />,
              componentClass: "no-max-width-mobile",
            }}
          />
          <div className="pt-[48px] lg:pt-[168px]"></div>
        </>
      );
    })()
  );
}
