import {ComponentShowModal} from "./ComponentShowModal";
import {useContext, useEffect, useState} from "react";
import {getCompanyJobList} from "../../service/companyRequest";
import {TError} from "../../types/TError";
import {TCompanyJobList} from "../../types/TCompanyData";
import {MainContext} from "../../context/MainContext";
import CLoadingAnimation from "../CLoadingAnimation";
import {ModalButton} from "../Input/ModalButton";
import {useNavigate} from "react-router-dom";
import {NotificationType} from "../Toast/Toast";


interface IProps {
  isOpen: boolean
  onClose: () => void
  projectTranslations: (key:string)=>string
}

export function CCompanyJobsModal({projectTranslations, isOpen, onClose}: IProps) {

  const mainContext = useContext(MainContext)

  const [isLoading, setIsLoading] = useState(false)
  const [jobsData, setJobsData] = useState<undefined | TCompanyJobList>(undefined)

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      getCompanyJobList().then((response) => {
        setIsLoading(false)
        if (response instanceof TError) {
          mainContext?.showToast(NotificationType.Fail, 'getProject')
        } else {
          setJobsData(response)
        }
      })
    }
  }, [isOpen]);

  const navigate=useNavigate()

  return <ComponentShowModal onClose={() => onClose()}
                             rightSpacing={18}
                             componentClass={'mt-[30px]'}
                             title={mainContext?.getTranslation('menu', 'ilanlarim')}
                             component={
                               isOpen ? <div className={'w-full flex-col'}>
                                 {isLoading ? <CLoadingAnimation size={100}/> : <>
                                   <p className={'text-14-24-700'}>{projectTranslations('aktif-ilanlarim')}</p>
                                   <div className={'flex-col pt-[20px] gap-[20px]'}>
                                     {/*berk undo*/}
                                     {jobsData?.activeJobs.map((job, index) => {
                                         const text = job.contactPerson.isDefined ?
                                           projectTranslations('tarihinde-tarafindan-olusturuldu').replace('{val2}', `${job.contactPerson.name} ${job.contactPerson.surname}`)
                                             .replace('{val1}', job.startDate??'') :
                                           projectTranslations('tarihinde-olusturuldu').replace('{val}', job.startDate??'')
                                         return (
                                           <div key={'activeJob'+index}
                                             className={'rounded-[10px] border-purple py-[32px] px-[16px] flex gap-[16px]'}>
                                             <div className={'flex-col flex-1'}>
                                               <p className={'text-15-24-700'}>{job.title}</p>
                                               <p className={'text-10-24-400'}>{text}</p>
                                             </div>
                                             <div className={'flex-col'}>
                                               <p className={'text-15-24-700'}>{job.offerCount}</p>
                                               <p className={'text-10-24-400'}>{projectTranslations('gelen-teklifler')}</p>
                                             </div>
                                             <div className={'flex-col justify-center'}>
                                               <ModalButton textClassName={'text-10-16-600 text-white'}
                                                            className={'div-green !px-[32px] !py-[8px]'} loading={false}
                                                            disabled={false}
                                                            text={mainContext?.getTranslation('common', 'incele') ?? ''}
                                                            onClick={() => {
                                                              navigate('/company-project/'+job.projectKeyID)
                                                            }}/>
                                             </div>
                                           </div>
                                         )
                                       }
                                     )}
                                   </div>
                                   {(jobsData?.inactiveJobs.length ?? 0) > 0 &&
                                       <div className={'mt-[20px]'}>
                                           <p className={'text-14-24-700'}>{projectTranslations('inaktif-ilanlarim')}</p>
                                           <div className={'flex-col mt-[20px] gap-[20px]'}>
                                             {jobsData?.inactiveJobs.map((job, index) => {
                                                 const text = job.contactPerson.isDefined ?
                                                   projectTranslations('tarihinde-tarafindan-olusturuldu').replace('{val2}', `${job.contactPerson.name} ${job.contactPerson.surname}`)
                                                     .replace('{val1}', job.startDate??'') :
                                                   projectTranslations('tarihinde-olusturuldu').replace('{val}', job.startDate??'')
                                                 return (
                                                   <div key={'inactiveJob'+index}
                                                     className={'rounded-[10px] border-purple py-[32px] px-[16px] flex gap-[16px]'}>
                                                     <div className={'flex-col flex-1'}>
                                                       <p className={'text-15-24-700'}>{job.title}</p>
                                                       <p className={'text-10-24-400'}>{text}</p>
                                                     </div>
                                                     <div className={'flex-col'}>
                                                       <p className={'text-15-24-700'}>{job.offerCount}</p>
                                                       <p
                                                         className={'text-10-24-400'}>{projectTranslations('gelen-teklifler')}</p>
                                                     </div>
                                                     <div className={'flex-col justify-center'}>
                                                       <ModalButton textClassName={'text-10-16-600 text-white'}
                                                                    className={'div-green !px-[32px] !py-[8px]'}
                                                                    loading={false}
                                                                    disabled={false}
                                                                    text={mainContext?.getTranslation('common', 'incele') ?? ''}
                                                                    onClick={() => {
                                                                      navigate('/company-project/'+job.projectKeyID)
                                                                    }}/>
                                                     </div>
                                                   </div>
                                                 )
                                               }
                                             )}
                                           </div>
                                       </div>
                                   }
                                 </>
                                 }
                               </div> : undefined
                             }/>
}
