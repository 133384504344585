export interface IDataProps {
  data?: DataProps;
  getTranslation( key: string): string
}

export class DataProps {
  name: string;
  extra1?: string;
  extra2?: string;
  extra3?: string;
  extra4?: string;
  extra5?: string;
  extra6?: string;
  extra7?: string;
  extra8?: string;
  extra9?: string;
  pagePictureModelList?: PictureData[];

  constructor(
    name: string,
    extra1: string,
    extra2: string,
    extra3: string,
    extra4: string,
    extra5: string,
    extra6: string,
    extra7: string,
    extra8: string,
    extra9: string,
    pagePictureModelList: PictureData[]
  ) {
    this.name = name;
    this.extra1 = extra1;
    this.extra2 = extra2;
    this.extra3 = extra3;
    this.extra4 = extra4;
    this.extra5 = extra5;
    this.extra6 = extra6;
    this.extra7 = extra7;
    this.extra8 = extra8;
    this.extra9 = extra9;
    this.pagePictureModelList = pagePictureModelList;
  }

  static parseAll(data: any): DataProps[] {
    const list = data.data.map((item: any) => {
      return new DataProps(
        item.name,
        item.extra1,
        item.extra2,
        item.extra3,
        item.extra4,
        item.extra5,
        item.extra6,
        item.extra7,
        item.extra8,
        item.extra9,
        item.pagePictureModelList.map((element: any) => {
          return new PictureData(element.pictureName);
        })
      );
    });
    return list;
  }
}

export class PictureData {
  pictureName: string;

  constructor(pictureName: string) {
    this.pictureName = pictureName;
  }

  getUrl(): string {
    if(this.pictureName === undefined) return "";
    return "https://www.pofft.com/Upload/PagePicture/" + this.pictureName;
  }
}
