import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { absoluteCenter } from "src/utils/window";
import CLoadingAnimation from "../components/CLoadingAnimation";
import { MainContext } from "../context/MainContext";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { passRegex } from "src/types/Regex";
import { resetPassRequest } from "src/utils/request";

export function ResetPassword() {
  const mainContext = useContext(MainContext);
  const defFunc = (parent: string, child: string) => {
    return "";
  };

  const translationsLoaded = mainContext?.translationsLoaded ?? false;
  const getTranslation = mainContext?.getTranslation ?? defFunc;

  const [searchParams] = useSearchParams();

  const [requesting, setRequesting] = useState(false);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [pass1Error, setPass1Error] = useState<string | undefined>(undefined);
  const [pass2Error, setPass2Error] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  function continueEnabled() {
    return (
      pass1Error == undefined &&
      pass1Error == undefined &&
      pass1 == pass2 &&
      pass2.length > 0
    );
  }

  useEffect(() => {
    var noErrorFound = true;

    if (pass1.length == 0) {
      setPass1Error(undefined);
    } else {
      if (pass1.length < 8) {
        setPass1Error(
          mainContext!.getTranslation("giris-kayit", "enaz-8karakter")
        );
        noErrorFound = false;
      } else {
        if (passRegex.test(pass1)) {
          setPass1Error(undefined);
        } else {
          setPass1Error(
            mainContext!.getTranslation("giris-kayit", "sifre-ozel-karakter")
          );
          noErrorFound = false;
        }
      }
    }

    if (pass2.length == 0) {
      setPass2Error(undefined);
    } else {
      if (pass2.length < 8) {
        setPass2Error(
          mainContext!.getTranslation("giris-kayit", "enaz-8karakter")
        );
        noErrorFound = false;
      } else {
        if (passRegex.test(pass2)) {
          setPass2Error(undefined);
        } else {
          setPass2Error(
            mainContext!.getTranslation("giris-kayit", "sifre-ozel-karakter")
          );
          noErrorFound = false;
        }
      }
    }

    if (noErrorFound && pass2 != pass1) {
      setPass2Error("Şifreleriniz uyuşmamaktadır");
    }
  }, [pass1, pass2]);

  const [login, setlogin] = useState("");
  const [keyID, setkeyID] = useState("");
  const [key, setkey] = useState("");
  const [memberType, setmemberType] = useState("");

  const [succeeded, setSucceeded] = useState(false);

  useEffect(() => {
    const vlogin = searchParams.get("login") ?? "";
    const vkeyID = searchParams.get("keyID") ?? "";
    const vkey = searchParams.get("key") ?? "";
    const vmemberType = searchParams.get("memberType") ?? "";
    setlogin(vlogin ?? "");
    setkeyID(vkeyID ?? "");
    setkey(vkey ?? "");
    setmemberType(vmemberType ?? "");
  }, []);

  async function resetPassword() {
    if (pass1 == pass2) {
      const isSuccess = await resetPassRequest(pass1, login, keyID, key, memberType);
      if (isSuccess) {
        setSucceeded(true);
      }else{

      }
    }
  }

  return (
    <div className="h-[100vh] w-[100vw]">
      <CLoadingAnimation fullscreen={true} />
      <Modal
        open={
          translationsLoaded &&
          login != "" &&
          keyID != "" &&
          key != "" &&
          memberType != ""
        }
        onClose={() => {
          return;
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={absoluteCenter}>
          {translationsLoaded && (
            <div className="w-[360px] lg:w-[380px] p-[24px] bg-white rounded-[8px] flex-col justify-center items-center">
              <p className="text-18-24-600 text-black">
                {getTranslation("giris-kayit", "sifre-sifirlama")}
              </p>
              <div className="pt-[32px] w-full flex-col items-start">
                {getTranslation("giris-kayit", "enaz-8karakter")}
                <div
                  className="w-[100%] pt-[24px]"
                  style={requesting ? { pointerEvents: "none" } : {}}
                >
                  <TextField
                    id="outlined-basic"
                    label={mainContext?.getTranslation("common", "input-sifre")}
                    variant="outlined"
                    className="basicInput !w-full"
                    value={pass1}
                    onChange={(e) => {
                      setPass1(e.target.value);
                    }}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {pass1Error && (
                    <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                      {pass1Error}
                    </div>
                  )}
                </div>
                <div
                  className="w-[100%] pt-[24px]"
                  style={requesting ? { pointerEvents: "none" } : {}}
                >
                  <TextField
                    id="outlined-basic"
                    label={mainContext?.getTranslation(
                      "common",
                      "input-sifretekrar"
                    )}
                    variant="outlined"
                    className="basicInput !w-full"
                    value={pass2}
                    onChange={(e) => {
                      setPass2(e.target.value);
                    }}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {pass2Error && (
                    <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                      {pass2Error}
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-[24px] w-full">
                <Button
                  className="purpleButton w-full"
                  disabled={!continueEnabled()}
                  onClick={() => {
                    if (requesting) {
                      return;
                    }
                    resetPassword();
                  }}
                >
                  {requesting ? (
                    <CLoadingAnimation size={24} />
                  ) : (
                    <span>
                      {mainContext!.getTranslation("common", "devam")}
                    </span>
                  )}
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
      <Modal
        open={succeeded && openSuccess}
        onClose={() => {
          setOpenSuccess(false);
          window.location.href=('/')
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={absoluteCenter}>
          <div className="w-[360px] lg:w-[380px] p-[24px] bg-white rounded-[8px] flex-col justify-center items-center">
            <div className="flex-col items-center">
              <img src="/images/forgot_pass_success.svg" />
              <div className="pt-[26px] text-black text-18-24-600 text-center">
                {mainContext?.getTranslation(
                  "giris-kayit",
                  "sifre-sifirlama-basarili"
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
