import { IDataProps } from "../types/DataProps";
import { isDesktop } from '../utils/window';

function CHeroAboutUs({ data,getTranslation }: IDataProps) { 
  
  return (
    <div className="homeHero">
      <div className="homeHeroImage">
        {isDesktop()?
        <img src="/images/home_hero_background.svg" />
        :
        <img src="/images/about_hero_mobile_background.svg" /> 
}
      </div>
      <div className="flex justify-between main-width m-auto main-padding pt-[132px] lg:pt-[152px] relative items-center h-full flex-col lg:flex-row">
        <div className="flex-col justify-left lg:pr-[88px] flex-1 text-center lg:text-start">
          <div className="text-20-24-400 text-grey">{getTranslation('hero_header').toUpperCase()}</div>
          <div className="pt-[16px] text-32-40-500 text-black">{getTranslation('hero_title')}</div>
          <div className="pt-[16px] text-16-24-400 text-grey">{getTranslation('hero_content')}</div>
        </div>
        <div className="shadow-image">
          <img
            src={data?.pagePictureModelList?.[0].getUrl()}
            style={{
              filter: "drop-shadow(0px 20px 80px rgba(80, 66, 17, 0.13))",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CHeroAboutUs;
