import {FilterModal} from "./FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {FilterPageProps, FilterConfig, JobFilterType} from "../../types/TFilter";
import './Filter.scss'
import 'dayjs/locale/tr';
import dayjs from "dayjs";
import {NumericFormat} from "react-number-format";
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {CSelectWithSearchForFilterSingle} from "../Input/CSelectWithSearchForFilterSingle";

export interface DeliveryDateValue {
  amount: number
  type: string
}

const jobFilterType = JobFilterType.DeliveryDate;

interface CustomFilter {
  value: number | undefined
  counter: number
}

export function FilterDeliveryDate({isOpen, onClose, onSave, filters}: FilterPageProps) {
  const mainContext = useContext(MainContext);
  // const [tempVal, setTempVal] = useState<DeliveryDateValue | null>(null)

  // const [startDate, setStartDate] = useState<dayjs.Dayjs | undefined>(undefined)
  // const [endDate, setEndDate] = useState<dayjs.Dayjs | undefined>(undefined)
  // useEffect(() => {
  //   if (startDate != undefined && endDate != undefined) {
  //     if (!endDate.isAfter(startDate)) {
  //       setEndDate(startDate)
  //     }
  //   }
  // }, [startDate]);

  const [amount, setAmount] = useState<CustomFilter>({counter: 0, value: undefined})
  const [dateType, setDateType] = useState<FilterConfig | undefined>(undefined)

  const [options, setOptions] = useState<FilterConfig[]>([
    {label: 'Gün', id: 1},
    {label: 'Hafta', id: 2},
    {label: 'Ay', id: 3},
    {label: 'Yıl', id: 4},
  ])

  function handleSave() {
    if (amount.value == undefined || dateType == undefined) {
      onSave(undefined)
    } else {
      onSave([{id: amount.value, label: amount.value + " "}, dateType])
    }
    onClose();
  }

  useEffect(() => {
    setAmount((a) => {
        return {
          counter: a.counter + 1,
          value:
            (filters?.length ?? 0 > 0) ? filters?.[0].id : undefined
        }
      }
    )
  }, [filters]);

  function getAmountValue(): string {
    if (amount.value != undefined) {
      return '' + amount.value
    }
    if (filters?.length ?? 0 > 0) {
      return filters![0].label
    }
    return ''
  }

  let now = dayjs();
  return <FilterModal
    title={mainContext!.getTranslation('filters', jobFilterType)}
    header={mainContext!.getTranslation('filters', 'teslimat-suresi-secin')}
    desc={mainContext!.getTranslation('filters', 'teslim-suresini-planla')}
    isOpen={isOpen} onClose={onClose} onSave={handleSave}>
    <div className={'input-select'}>
      <div className={'flex-col gap-[12px]'}>
        {/*<p className={'text-16-24-500 text-black'}>{mainContext!.getTranslation('common', 'aralik-giriniz')}</p>*/}
        <div className={'flex gap-[12px]'}>
          <NumericFormat
            autoFocus={true}
            key={'teslimatsuresi_' + amount.counter}
            className={'numberInput'}
            max={999}
            min={0}
            defaultValue={getAmountValue()}
            value={getAmountValue()}
            onValueChange={(values) => {
              let newVal = parseInt(values.value);
              if (newVal > 999) {
                newVal = 999;
              }
              setAmount({value: newVal, counter: amount.counter + 1})
            }
            }
            valueIsNumericString
          />
          <CSelectWithSearchForFilterSingle options={options}
                                      val={dateType ?? (filters?.length ?? 0 > 1) ? filters?.[1] : undefined}
            //@ts-ignore
                                      onChange={setDateType}/>
        </div>
        {/*<div className={'flex justify-between'}>*/}
        {/*  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>*/}
        {/*    <DatePicker views={['year', 'month', 'day']}*/}
        {/*                value={startDate}*/}
        {/*                onAccept={(e) => setStartDate(e ?? undefined)}*/}
        {/*                minDate={now.add(1, 'day')}*/}
        {/*    />*/}
        {/*  </LocalizationProvider>*/}
        {/*  <div className={'px-[8px] text-purple self-center'}>-</div>*/}
        {/*  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'tr'}>*/}
        {/*    <DatePicker views={['year', 'month', 'day']}*/}
        {/*                value={endDate}*/}
        {/*                onAccept={(e) => setEndDate(e ?? undefined)}*/}
        {/*                minDate={startDate == undefined ? now.add(1, 'day') : startDate}*/}
        {/*    />*/}
        {/*  </LocalizationProvider>*/}
        {/*</div>*/}
      </div>
    </div>
  </FilterModal>
}
