import {InputAdornment, TextField} from "@mui/material";
import {CProfile} from "src/components/CProfile";
import {CSearchResult} from '../components/CSearchResult';
import {MainContext} from "src/context/MainContext";
import {useContext, useEffect, useState} from "react";
import {Translation} from "../types/Translation";
import {FilterConfig} from "../types/TFilter";
import CLoadingAnimation from "../components/CLoadingAnimation";
import {FilterProjects, OrderType} from "../service/projectRequest";
import {ParseSearchResults, TSearchResponse} from "../types/TProject";
import {useNavigate} from "react-router-dom";
import {PageState} from "../types/Context";
import {getV2, postV2} from "../utils/request";
import {NotificationType} from "../components/Toast/Toast";
import {TError} from "../types/TError";

interface FilterState {
  filters: Map<string, FilterConfig[]>,
  page: number,
  pageSize: number,
}

const emptyFilters: FilterState = {
  filters: new Map<string, FilterConfig[]>(),
  page: 1,
  pageSize: 8
}

export function Dashboard() {
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);

  const [isLoaded, setIsLoaded] = useState(false)
  const [favoriteCount, setFavoriteCount] = useState(0)

  useEffect(() => {
    if (localStorage.getItem('userData') == null) {
      navigate('/')
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      //Called onFiltersApplied callback by CProfile
      // searchJobs(filters.filters, 1, filters.pageSize, orderType)
      SetFavoriteCount();
    }
  }, [isLoaded]);

  function SetFavoriteCount() {
    getFavorites().then(f => {
      if (f) {
        setFavoriteCount(f.totalCount)
      }
      if (window.location.href.endsWith('/liked')) {
        setOrderType(OrderType.Liked)
      }
    })
  }

  useEffect(() => {
    if (mainContext?.userData) {
      const expD = new Date(mainContext.userData.expireDate).getTime() / 1000
      const nowD = new Date().getTime() / 1000
      //berkExpire
      if (expD - nowD > 100) {
        setIsLoaded(true)
      } else {
        setTimeout(() => {
          mainContext.setSigninupState(PageState.Signin)
        }, 1500)
      }
    }
  }, [mainContext?.userData]);

  const [isCompact, setIsCompact] = useState(false)

  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  const [filters, setFilters] = useState(emptyFilters)
  const [textFilter, setTextFilter] = useState<string | undefined>(undefined)
  const [requesting, setRequesting] = useState(false)
  const [jobs, setJobs] = useState<TSearchResponse | undefined>(undefined)
  const [orderType, setOrderType] = useState(OrderType.BestMatch)
  const [totalPages, setTotalPages] = useState(1)

  async function searchJobs(Filters: Map<string, FilterConfig[]>, page: number, pageSize: number, orderType: OrderType, TextFilter?: string) {
    if (mainContext?.isExpired('dashboard1') ?? false) {
      return
    }
    window.scrollTo(0, 0)
    setTextFilter(TextFilter)

    setFilters({filters: Filters, page: page, pageSize: pageSize})
    setRequesting(true)
    let Jobs: any | undefined = undefined

    // if (orderType == OrderType.Liked) {
    //   Jobs = await getFavorites()
    // } else {
    const response = await FilterProjects(Filters, page, pageSize, orderType, TextFilter)
    //TODO: set total pages
    //TODO: set current page => filters.page
    Jobs = ParseSearchResults(response.projectList, response.totalCount)
    // }
    setRequesting(false)
    setJobs(Jobs)
    setTotalPages(Math.ceil(Jobs.totalCount / pageSize))
  }

  async function getFavorites() {
    setRequesting(true)
    return getV2('/Favorite/list?favoriteRelationType=Project&pageSize=50&pageNumber=1&sortColumnName=ID&sortType=asc').then((resp) => {
      setRequesting(false)
      if (resp instanceof TError) {
        mainContext?.showToast(
          NotificationType.Fail,
          'filterbypaging'
        );
        return undefined
      }
      const projects = resp.data.favoriteList.filter((f: any) => f.detail != undefined).map((f: any) => {
        const detail = f.detail.projectDetail
        return detail
      })
      const Jobs = ParseSearchResults(projects, resp.data.totalCount)
      return Jobs
    })
  }

  async function favoriteProject(projectId: string, val: boolean) {
    let response: any = undefined
    if (val) {
      let data = {
        FavoriteRelationType: 'Project',
        favoriteRelationKey: projectId,
      };
      response = await postV2('/Favorite/create', data)
      await SetFavoriteCount()
    } else {
      response = await getV2('/Favorite/deleteType?favoriteRelationType=Project&favoriteRelationKey=' + projectId)
      await SetFavoriteCount()
    }
    if (response instanceof TError) {
      mainContext?.showToast(
        NotificationType.Fail,
        'favoriteProject'
      );
      return
    }
    if (response.data.result == true) {
      mainContext?.showToast(NotificationType.Success, val ? 'favoriteProject' : 'unfavoriteProject')
      const newJobs: TSearchResponse = {...jobs!}
      const newPs = newJobs!.projectList!.map(p => {
        if (p.projectKeyID == projectId) {
          p.liked = val
        }
        return p
      })
      newJobs.projectList = newPs
      setJobs(newJobs)
    } else {
      mainContext?.showToast(NotificationType.Fail, val ? 'favoriteProject' : 'unfavoriteProject')
    }
  }

  function onFiltersApplied(Filters: Map<string, FilterConfig[]>) {
    searchJobs(Filters, emptyFilters.page, emptyFilters.pageSize, orderType)
  }

  function getPagerButton(pageNum: number) {
    return <div role={'button'} onClick={() => {
      searchJobs(filters.filters, pageNum, filters.pageSize, orderType, textFilter)
    }} className={'pager' + (filters.page == pageNum ? ' selected' : '')}>{pageNum}</div>
  }

  function getPaginationPages() {
    const divs = []
    if (filters.page < 5) {
      for (var i = 1; i <= Math.min(totalPages, 5); i++) {
        divs.push(getPagerButton(i))
      }
      if (totalPages == 6) {
        divs.push(getPagerButton(6))
      } else if (totalPages > 5) {
        divs.push(<div className={'pager'}>...</div>)
        divs.push(getPagerButton(totalPages))
      }
    } else if (filters.page > totalPages - 4) {
      if (filters.page == (totalPages - 4)) {
        divs.push(getPagerButton(filters.page))
      } else {
        divs.push(getPagerButton(1))
        divs.push(<div className={'pager'}>...</div>)
      }
      for (var i = Math.max(totalPages - 4, 1); i <= totalPages; i++) {
        divs.push(getPagerButton(i))
      }
    } else {
      divs.push(getPagerButton(1))
      if (filters.page == 5) {
        divs.push(getPagerButton(2))
      } else if (filters.page > 3) {
        divs.push(<div className={'pager'}>...</div>)
      }
      for (var i = filters.page - 2; i <= filters.page + 2; i++) {
        divs.push(getPagerButton(i))
      }
      if (filters.page == totalPages - 4) {
        divs.push(getPagerButton(totalPages - 1))
      } else if (filters.page < totalPages - 3) {
        divs.push(<div className={'pager'}>...</div>)
      }
      divs.push(getPagerButton(totalPages))
    }
    return divs
  }

  return (
    <div
      className="justify-between main-width-desktop m-auto main-padding pt-[96px] pb-[48px] lg:pb-[72px] items-center h-full">
      {!isLoaded ?
        <div className={'h-[100vh] w-full flex items-center justify-center'}>
          <CLoadingAnimation/>
        </div>
        :
        <div className="flex w-full relative">
          <CProfile onFiltersApplied={onFiltersApplied} hideFilters={textFilter != undefined && textFilter.length > 0}/>
          <div className={'w-full relative'}>
            <div className={'flex-col w-full flex-1 h-full max-h-[100%]'}>
              <div className="p-[24px] pt-0 pr-0 dashboardHeader">
                <div className="pt-[24px]">
                  <div
                    className="rounded-[16px] div-mindaro py-[16px] px-[24px]"
                    style={{boxShadow: "0px 20px 80px 0px #EBECF0"}}
                  >
                    <div className="flex justify-between items-center">
                      <div className="text-24-40-500 text-black">
                        {mainContext?.getTranslation('common', 'hadi-basla')}
                      </div>
                      <TextField
                        onKeyDown={(e) => {
                          if (e.key == 'Enter') {
                            //@ts-ignore
                            searchJobs(filters.filters, 1, emptyFilters.pageSize, orderType, e.target.value)
                          }
                        }}
                        className="dashboard-search"
                        hiddenLabel
                        placeholder={mainContext?.getTranslation('common', 'is-ara')}
                        sx={{
                          maxWidth: "420px",
                          minWidth: "420px",
                          backgroundColor: "#fff",
                          borderRadius: "16px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src="/images/search.png"/>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    {/*<div className="flex justify-between gap-[16px] pt-[24px]">*/}
                    {/*  <div className="w-full flex-1">*/}
                    {/*    <CFilters*/}
                    {/*      customParentClass="!pb-0 !pt-0"*/}
                    {/*      filters={OPofftMarketplaceSection(mainContext!.getTranslation).filters}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*  <Button className="purpleButtonMini" onClick={() => {}}>*/}
                    {/*    Ara*/}
                    {/*  </Button>*/}
                    {/*</div>*/}
                    {/*<div className="flex justify-between gap-[24px] pt-[16px]">*/}
                    {/*  {quickFilters.map((filter, index) => {*/}
                    {/*    return (*/}
                    {/*      <div className="rounded-[16px] bg-white py-[4px] px-[12px] text-14-24-500 text-fullblack text-center flex items-center">*/}
                    {/*        {filter.name}*/}
                    {/*      </div>*/}
                    {/*    );*/}
                    {/*  })}*/}
                    {/*</div>*/}
                  </div>
                  <div className={'flex items-center justify-between pt-[25px] pr-[28px]'}>
                    <div className={'flex gap-[16px]'}>
                      <div role={'button'} onClick={() => {
                        setOrderType(OrderType.BestMatch)
                        searchJobs(filters.filters, 1, emptyFilters.pageSize, OrderType.BestMatch)
                      }}
                           className={'roundLabel' + (orderType == OrderType.BestMatch ? ' selected' : '')}>{mainContext?.getTranslation('filters', 'en-iyi-eslesenler')}</div>
                      <div role={'button'} onClick={() => {
                        setOrderType(OrderType.Newest)
                        searchJobs(filters.filters, 1, emptyFilters.pageSize, OrderType.Newest)
                      }} className={'roundLabel' + (orderType == OrderType.Newest ? ' selected' : '')}>
                        {mainContext?.getTranslation('filters', 'en-yeniler')}
                      </div>
                      <div role={'button'} onClick={() => {
                        setOrderType(OrderType.Liked)
                        searchJobs(filters.filters, 1, emptyFilters.pageSize, OrderType.Liked)
                      }}
                           className={'flex gap-[10px] roundLabel' + (orderType == OrderType.Liked ? ' selected' : '')}>
                        {mainContext?.getTranslation('filters', 'kaydedilenler')}
                        <div className={'badge'}>{favoriteCount}</div>
                      </div>
                    </div>
                    <div className={'flex gap-[8px]'}>
                      <div className={`listType ${isCompact ? 'selected' : ''}`} role={'button'} onClick={() => {
                        setIsCompact(true)
                      }}>
                        <img src={`/images/tableLayoutGrid${isCompact ? 'Selected' : ''}.svg`}/>
                      </div>
                      <div className={`listType ${!isCompact ? 'selected' : ''}`} role={'button'} onClick={() => {
                        setIsCompact(false)
                      }}>
                        <img src={`/images/tableLayoutList${!isCompact ? 'Selected' : ''}.svg`}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'w-full'}>
                {requesting &&
                    <div className={'h-full w-full flex px-[24px] z-[9999]'}>
                        <div className={'h-full w-full flex flex-col justify-center items-center'}>
                            <CLoadingAnimation size={120}/>
                        </div>
                    </div>
                }
                {!requesting &&
                    <div className={'w-full h-full flex flex-col'}>
                        <div
                            className={`h-full p-[24px] pt-0 pb-[36px] gap-[24px] grid ${isCompact ? 'grid-cols-2' : 'grid-cols-1'}`}
                            // style={{
                            //   gridAutoRows: '1fr'
                            //}}
                        >
                          {jobs?.projectList?.map((job) => {
                            return <CSearchResult favoriteProject={favoriteProject} compact={isCompact} data={job}/>
                          })}
                        </div>
                    </div>
                }
              </div>
              {!requesting &&
                  <div className={'pagination'}>
                    {filters.page > 1 &&
                        <img role={'button'}
                             onClick={() => searchJobs(filters.filters, filters.page - 1, emptyFilters.pageSize, orderType, textFilter)}
                             src={'/images/ArrowLeft.svg'}/>
                    }
                    {getPaginationPages()}
                    {(filters.page < totalPages) &&
                        <img role={'button'}
                             onClick={() => searchJobs(filters.filters, filters.page + 1, emptyFilters.pageSize, orderType, textFilter)}
                             src={'/images/ArrowRight.svg'}/>
                    }
                  </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
}
