import {useContext, useEffect, useState} from "react";
import {MainContext} from "src/context/MainContext";
import {RBlog} from "../types/RBlogs";
import {getV2} from "../utils/request";
import CLoadingAnimation from "./CLoadingAnimation";
import {CTopBlogCard} from "./CTopBlogCard";
import {isDesktop} from "src/utils/window";
import {getTranslations} from "../service/commonRequest";
import {TError} from "../types/TError";
import {NotificationType} from "./Toast/Toast";

function CBlogs() {
  const mainContext = useContext(MainContext);
  const IsDesktop = isDesktop();

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState<undefined | RBlog[]>(undefined);

  useEffect(() => {
    if (mainContext?.publicToken) {
      getV2(
        `/page/getpagebypaging?position=blog&pageSize=6&pageNumber=${page}&sortColumnName=ID&sortType=desc`, true
      ).then((resp) => {
        if(resp instanceof TError){
          mainContext.showToast(NotificationType.Fail, 'getBlogs')
          return
        }
        const blogsData = RBlog.parse(resp);
        if (blogs == undefined) {
          setBlogs(blogsData);
        } else {
          setBlogs((oldOnes) => {
            return oldOnes!.concat(blogsData);
          });
        }
        setLoading(false);
      });
    }
  }, [mainContext?.publicToken, page]);

  return (
    <>
      {blogs == undefined ? (
        <CLoadingAnimation/>
      ) : (
        <div className="flex-col gap-[32px]">
          <div className="flex flex-wrap justify-around gap-x-[40px] gap-y-[30px] pt-[48px]">
            {blogs.map((item) => {
              return (
                <div key={'blog_' + item.id} className={IsDesktop ? 'w-[30%]' : "w-[90%]"}>
                  <CTopBlogCard blog={item} parentClass="h-full"/>
                </div>
              );
            })}
          </div>
          <div className="w-full flex">
            <div
              className={
                (loading ? "div-grey" : "div-purple") +
                " text-white rounded-[8px] px-[48px] py-[16px] m-auto text-center"
              }
              role="button"
              onClick={() => {
                setLoading(true);
                setPage((old) => old + 1);
              }}
            >
              {mainContext?.getTranslation("common", "daha-fazla-goster")}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CBlogs;
