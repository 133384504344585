import { isDesktop } from "src/utils/window";
import { TPageSection } from "../types/TComponents";
import CEllipseTextBox from "./CEllipseTextBox";

interface PRowSection {
  data: TPageSection;
}

function CPageSection({ data }: PRowSection) { 
  return (
    <div className={`pageRow pb-[48px] lg:pb-[72px] ${data.parentClass}`}>
      <div className={`pageRow main-width main-padding`}>
        {data.title && <CEllipseTextBox text={data.title} />}
        {data.miniHeader && (
          <div className="text-20-24-400 text-grey m-auto text-center w-full">
            {data.miniHeader}
          </div>
        )}
        {data.header && (
          <div className={`pt-[16px] text-black w-[640px] m-auto text-center w-full ${data.headerTextClass??'text-24-40-500_32-40-500'}`}>
            {data.header}
          </div>
        )}
        {data.sub && (
          <div className="pt-[16px] text-16-24-400 text-grey w-[640px] m-auto text-center w-full">
            {data.sub}
          </div>
        )}
      </div> 
      {data.component && (
        <div className={`text-center main-width ${isDesktop()?'main-padding':''} ${data.componentClass}`}>
          {data.component}
        </div>
      )}
    </div>
  );
}

export default CPageSection;
